import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import SendIcon from "@mui/icons-material/Send";
import TrophyIcon from "@mui/icons-material/EmojiEvents";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import BugReportIcon from "@mui/icons-material/BugReport";
import { useDialog } from "../context/DialogContext";

const BottomNav = ({ isDevAuthorized }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const { isDialogOpen } = useDialog();

  const isProfilePage = location.pathname === "/profile";

  const navItems = useMemo(
    () => [
      { path: "/home", icon: SendIcon, label: "Picks" },
      { path: "/leaderboard", icon: TrophyIcon, label: "Leaderboard" },
      { path: "/scores", icon: ScoreboardIcon, label: "Scores" },
      ...(isDevAuthorized
        ? [{ path: "/dev", icon: BugReportIcon, label: "Dev Tools" }]
        : []),
    ],
    [isDevAuthorized]
  );

  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
      navigate(navItems[newValue].path);
    },
    [navigate, navItems]
  );

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;

    if (currentScrollY < 10) {
      setIsVisible(true);
    } else if (currentScrollY > lastScrollY) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }

    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const currentIndex = navItems.findIndex(
      (item) => item.path === location.pathname
    );
    if (currentIndex !== -1 && !isProfilePage) {
      setValue(currentIndex);
    } else {
      setValue(-1); // Set to -1 when on profile page or unknown route
    }
  }, [location.pathname, navItems, isProfilePage]);

  return (
    <nav
      className={`bottom-nav fixed left-0 right-0 bottom-0 z-[9999] bg-slate-800/80 backdrop-blur-md transition-transform duration-300 ${
        isVisible && !isDialogOpen ? "translate-y-0" : "translate-y-full"
      }`}
      style={{
        paddingBottom: "env(safe-area-inset-bottom)",
        height: "calc(64px + env(safe-area-inset-bottom))",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        willChange: "transform",
      }}
      aria-label="Bottom Navigation"
    >
      <BottomNavigation
        value={isProfilePage ? -1 : value}
        onChange={handleChange}
        showLabels
        sx={{
          height: "64px",
          backgroundColor: "transparent",
          "& .MuiBottomNavigationAction-root": {
            color: "rgb(203 213 225)", // slate-300
            fontFamily: "inherit",
            fontSize: "0.75rem",
            minWidth: "auto",
            padding: "6px 0",
            position: "relative",
            transition: "none", // Disable default transitions
          },
        }}
      >
        {navItems.map((item, index) => (
          <BottomNavigationAction
            key={item.path}
            icon={
              <item.icon
                fontSize="medium"
                sx={{
                  fontSize: 24,
                  color:
                    !isProfilePage && value === index ? "#60a5fa" : "inherit",
                }}
              />
            }
            label={item.label}
            disableRipple
            aria-label={`${item.label} page`}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "transparent",
                color: isProfilePage ? "rgb(203 213 225)" : "#60a5fa",
              },
              "& .MuiSvgIcon-root": {
                color:
                  !isProfilePage && value === index ? "#60a5fa" : "inherit",
                transition: "none", // Disable icon transitions
              },
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: "15%",
                width: "70%",
                height: "2px",
                backgroundColor: "#60a5fa",
                transform:
                  !isProfilePage && value === index ? "scaleX(1)" : "scaleX(0)",
                transition: "transform 0.3s ease-in-out",
              },
              // Disable hover and active states
              "&:hover, &:active": {
                backgroundColor: "transparent",
              },
              // Prevent icon size change on click
              "& .MuiBottomNavigationAction-label": {
                transition: "none",
              },
              "&.Mui-selected .MuiBottomNavigationAction-label": {
                fontSize: "0.75rem",
              },
            }}
          />
        ))}
      </BottomNavigation>
    </nav>
  );
};

export default React.memo(BottomNav);
