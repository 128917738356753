import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  ChevronDown,
  ChevronUp,
  Lock,
  Trophy,
  TrendingUp,
  Target,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import {
  weekDates,
  isWeekAvailable,
  getLatestAvailableWeek,
  getCurrentWeek,
} from "./weekDates";

import {
  getGamesForWeek,
  isPickCorrect,
  hasGameStarted,
  updateScores,
  hasGameFinished,
  hasLastGameStarted,
  getMondayNightScore,
  hasLastGameFinished,
} from "../gameData";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import { useLocation } from "react-router-dom";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Calendar, Check } from "lucide-react";
import { cn } from "@/lib/utils";
// import { toast } from "react-toastify";
import { useQuery, useMutation } from "convex/react";
import { api } from "../../convex/_generated/api";
import { useUser } from "@clerk/clerk-react";

const log = (message, data) => {};

const ViewModeSwitch = ({ viewMode, setViewMode, setViewTransitioning }) => {
  const handleViewChange = (newMode) => {
    if (newMode === viewMode) return;

    setViewTransitioning(true);
    setViewMode(newMode);

    // Add a minimum delay for the skeleton loader
    setTimeout(() => {
      setViewTransitioning(false);
    }, 300);
  };

  return (
    <div className="flex items-center justify-center space-x-2 bg-gray-800 rounded-full p-1 w-80 mx-auto mb-4">
      <button
        className={`flex-1 py-2 px-4 rounded-full text-sm font-medium transition-all duration-200 ${
          viewMode === "week"
            ? "bg-blue-600 text-white"
            : "text-gray-400 hover:text-white"
        }`}
        onClick={() => handleViewChange("week")}
      >
        Week
      </button>
      <button
        className={`flex-1 py-2 px-4 rounded-full text-sm font-medium transition-all duration-200 ${
          viewMode === "total"
            ? "bg-blue-600 text-white"
            : "text-gray-400 hover:text-white"
        }`}
        onClick={() => handleViewChange("total")}
      >
        Total
      </button>
      <button
        className={`flex-1 py-2 px-4 rounded-full text-sm font-medium transition-all duration-200 relative ${
          viewMode === "compare"
            ? "bg-blue-600 text-white"
            : "text-gray-400 hover:text-white"
        }`}
        onClick={() => handleViewChange("compare")}
      >
        Compare
        {/* beta badge */}
        {/* <span className="absolute -top-2 -right-2 bg-rose-500 text-white text-[10px] px-1.5 py-0.5 rounded-full font-semibold">
          BETA
        </span> */}
      </button>
    </div>
  );
};

const updateLeaderboard = (
  leaderboardData,
  viewMode,
  calculateRanks,
  selectedWeek,
  currentWeek,
  lastGameFinished
) => {
  log("Updating leaderboard", { viewMode, selectedWeek, currentWeek });
  const mondayNightScore = getMondayNightScore(selectedWeek);
  log("Monday night score", mondayNightScore);

  const sortedData = [...leaderboardData].sort((a, b) => {
    // First, sort by the number of correct picks
    const aScore =
      viewMode === "total" ? a.totalCorrectPicks : a.weeklyCorrectPicks;
    const bScore =
      viewMode === "total" ? b.totalCorrectPicks : b.weeklyCorrectPicks;

    if (bScore !== aScore) {
      return bScore - aScore;
    }

    // If there's a tie, use the tiebreaker
    const aTiebreaker = a.tiebreaker;
    const bTiebreaker = b.tiebreaker;

    if (mondayNightScore !== null) {
      // If both are under or equal to the actual score
      if (aTiebreaker <= mondayNightScore && bTiebreaker <= mondayNightScore) {
        return bTiebreaker - aTiebreaker; // Higher score wins
      }
      // If only a is under or equal
      else if (aTiebreaker <= mondayNightScore) {
        return -1; // a wins
      }
      // If only b is under or equal
      else if (bTiebreaker <= mondayNightScore) {
        return 1; // b wins
      }
      // If both are over
      else {
        return aTiebreaker - bTiebreaker; // Lower score wins
      }
    }

    // If no Monday night score available, sort by tiebreaker (higher is better)
    return bTiebreaker - aTiebreaker;
  });

  log("Sorted data", sortedData);

  const rankedData = calculateRanks(sortedData);
  log("Ranked data", rankedData);

  let winner = null;
  if (
    viewMode === "week" &&
    rankedData.length > 0 &&
    (selectedWeek < currentWeek || lastGameFinished)
  ) {
    winner = rankedData[0].username;
    log("Weekly winner", winner);
  }

  return { rankedData, winner };
};

const SkeletonRow = () => (
  <div className="bg-gray-800 rounded-xl overflow-hidden shadow-lg animate-pulse p-3 sm:p-4">
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-3 sm:space-x-4">
        <div className="h-12 w-12 sm:h-14 sm:w-14 rounded-full bg-gray-700"></div>
        <div>
          <div className="h-4 w-24 bg-gray-700 rounded"></div>
          <div className="h-3 w-16 bg-gray-700 rounded mt-2"></div>
        </div>
      </div>
      <div className="flex items-center space-x-4 sm:space-x-6">
        <div className="h-8 w-8 bg-gray-700 rounded"></div>
        <div className="h-8 w-8 bg-gray-700 rounded"></div>
      </div>
    </div>
  </div>
);

const ComparisonView = ({
  leaderboard,
  picks,
  selectedWeek,
  lastGameStarted,
}) => {
  const weekGames = getGamesForWeek(selectedWeek);

  return (
    <div className="overflow-x-auto -mx-2 sm:mx-0">
      <table className="w-full border-collapse min-w-[640px]">
        <thead>
          <tr className="border-b border-gray-700">
            <th className="p-2 text-left text-sm font-medium text-gray-400 sticky left-0 bg-gray-900 z-10 w-[120px]">
              User
            </th>
            {weekGames.map((game, index) => (
              <th key={index} className="p-2 px-4 text-center">
                <div className="flex items-center justify-center space-x-1">
                  <img
                    src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${game.away.abbreviation.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=20&h=20`}
                    alt={game.away.name}
                    className="w-4 h-4"
                  />
                  <span className="text-xs text-gray-400">@</span>
                  <img
                    src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${game.home.abbreviation.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=20&h=20`}
                    alt={game.home.name}
                    className="w-4 h-4"
                  />
                </div>
              </th>
            ))}
            <th className="p-2 text-center text-xs font-medium text-gray-400 w-[80px]">
              TB
            </th>
          </tr>
        </thead>
        <tbody>
          {leaderboard.map((user, index) => {
            const userPicks = picks[user.username]?.picks;
            const tiebreaker = picks[user.username]?.tiebreaker;

            return (
              <tr key={user.username} className="border-b border-gray-800">
                <td className="p-2 sticky left-0 bg-gray-900 z-10">
                  <span className="text-sm text-gray-200">{user.username}</span>
                </td>
                {weekGames.map((game, gameIndex) => {
                  const pick = userPicks?.[gameIndex];
                  const gameStarted = hasGameStarted(game.startTime);
                  const gameFinished = hasGameFinished(game);

                  if (!gameStarted) {
                    return (
                      <td key={gameIndex} className="p-2 text-center">
                        <div className="bg-gray-800 text-gray-600 w-16 py-2 rounded text-xs inline-flex items-center justify-center space-x-1">
                          <Lock className="h-3 w-3" />
                        </div>
                      </td>
                    );
                  }

                  if (!pick || pick === "Not Submitted") {
                    return (
                      <td key={gameIndex} className="p-2 text-center">
                        <div className="bg-yellow-900/30 text-yellow-400 w-16 py-0.5 rounded text-xs">
                          -
                        </div>
                      </td>
                    );
                  }

                  const [spread] = pick.split(" ");
                  const isCorrect = gameFinished && isPickCorrect(pick, game);
                  const isPush = gameFinished && game.winner === "PUSH";

                  return (
                    <td key={gameIndex} className="p-2 text-center">
                      <div
                        className={`inline-flex items-center justify-center w-16 py-0.5 rounded text-xs ${
                          !gameFinished
                            ? "bg-gray-800 text-gray-300"
                            : isPush
                              ? "bg-yellow-900/30 text-yellow-400"
                              : isCorrect
                                ? "bg-green-900/30 text-green-400"
                                : "bg-red-900/30 text-red-400"
                        }`}
                      >
                        <span>{spread.replace(/[()]/g, "")}</span>
                      </div>
                    </td>
                  );
                })}
                <td className="p-2 text-center">
                  <span
                    className={`text-xs font-medium ${
                      !lastGameStarted ? "blur-md" : "text-blue-400"
                    }`}
                  >
                    {tiebreaker || "-"}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const parsePicks = (pick) => {
  try {
    // If picks is already an object, return it
    if (typeof pick.picks === "object" && !Array.isArray(pick.picks)) {
      return pick.picks;
    }

    // If picks is a string, try to parse it
    if (typeof pick.picks === "string") {
      // First attempt: standard JSON parse
      try {
        const parsed = JSON.parse(pick.picks);
        return parsed;
      } catch (e) {
        console.log("Standard JSON parse failed:", e);

        // Second attempt: if it looks like an array
        if (pick.picks.startsWith("[") && pick.picks.endsWith("]")) {
          const arrayPicks = pick.picks
            .slice(1, -1)
            .split(",")
            .map((item) => item.trim())
            .filter(Boolean);

          // Convert array to object format
          return arrayPicks.reduce((acc, pick, index) => {
            acc[index] = pick;
            return acc;
          }, {});
        }

        // Third attempt: if it's a stringified object but malformed
        if (pick.picks.includes("{") && pick.picks.includes("}")) {
          // Try to clean up the string and parse again
          const cleaned = pick.picks.replace(/\s+/g, " ").trim();
          try {
            return JSON.parse(cleaned);
          } catch (e) {
            console.log("Cleaned JSON parse failed:", e);
          }
        }
      }
    }

    // If picks is an array, convert to object format
    if (Array.isArray(pick.picks)) {
      return pick.picks.reduce((acc, pick, index) => {
        acc[index] = pick;
        return acc;
      }, {});
    }

    console.error("Unable to parse picks:", pick);
    return {};
  } catch (error) {
    console.error("Error in parsePicks:", error, "Pick data:", pick);
    return {};
  }
};

const updateUserMetadata = async (user, imageUrl) => {
  if (!user?.username) {
    console.log("No username available, skipping Convex update");
    return;
  }

  try {
    await updateAvatarUrl({
      username: user.username,
      avatarUrl: imageUrl || null,
    });
  } catch (error) {
    console.warn("Failed to update avatar URL:", error);
  }
};

const useProfilesWithFallback = () => {
  const profiles = useQuery(api.users.getAllProfiles);

  if (!profiles) {
    console.warn("Failed to load user profiles, using fallback");
    return [];
  }

  return profiles;
};

const LeaderboardPage = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(getLatestAvailableWeek());
  const [error, setError] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [picks, setPicks] = useState({});
  const [weeklyCorrectPicks, setWeeklyCorrectPicks] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const currentWeekRef = useRef(null);
  const selectedWeekRef = useRef(selectedWeek);
  const [currentWeek, setCurrentWeek] = useState(getLatestAvailableWeek());
  const [lastGameStarted, setLastGameStarted] = useState(false);
  const [viewMode, setViewMode] = useState(() => {
    const savedViewMode = localStorage.getItem("leaderboardViewMode");
    return savedViewMode || "week";
  });
  const [weeklyWinner, setWeeklyWinner] = useState(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [artificialDelay, setArtificialDelay] = useState(false);
  const [lastGameFinished, setLastGameFinished] = useState(() => {
    // Initialize with the current state of the last game for the selected week
    return hasLastGameFinished(getLatestAvailableWeek());
  });
  const [showRouteSkeleton, setShowRouteSkeleton] = useState(false);
  const location = useLocation();
  const [viewTransitioning, setViewTransitioning] = useState(false);
  const [avatarsLoaded, setAvatarsLoaded] = useState(false);

  // Add Convex query for picks data
  const allUserPicks = useQuery(api.picks.getAllUserPicks);
  const [picksError, setPicksError] = useState(null);

  // Add Clerk's user hook and loading state
  const { user, isLoaded: isUserLoaded } = useUser();

  // Move the mutation hook to component level
  const updateAvatarUrl = useMutation(api.users.updateAvatarUrl);

  // Add the Convex query for user profiles
  const allProfiles = useProfilesWithFallback();

  // Modify the updateUserMetadata function
  const updateUserMetadata = async (user, imageUrl) => {
    if (!user?.username) {
      console.log("No username available, skipping Convex update");
      return;
    }

    try {
      await updateAvatarUrl({
        username: user.username,
        avatarUrl: imageUrl || null,
      });
    } catch (error) {
      console.warn("Failed to update avatar URL:", error);
    }
  };

  // Add this near the top of the component where other state is defined
  const [avatarCache, setAvatarCache] = useState(new Map());

  // Add request tracking
  const activeRequests = useRef(new Map());

  // Memoize these values to prevent unnecessary re-renders
  const memoizedProfiles = useMemo(() => allProfiles || [], [allProfiles]);
  const memoizedUserPicks = useMemo(() => allUserPicks || [], [allUserPicks]);

  useEffect(() => {
    selectedWeekRef.current = selectedWeek;
  }, [selectedWeek]);

  const calculateRanks = useCallback(
    (leaderboardData) => {
      let currentRank = 1;
      let previousScore = null;
      let rankIncrement = 0;

      return leaderboardData.map((row, index) => {
        const score =
          viewMode === "total" ? row.totalCorrectPicks : row.weeklyCorrectPicks;
        if (score !== previousScore) {
          currentRank += rankIncrement;
          rankIncrement = 1;
        } else {
          rankIncrement++;
        }
        previousScore = score;
        return { ...row, rank: currentRank };
      });
    },
    [viewMode]
  );

  // Modify the fetchLeaderboardData function to handle avatar preloading
  const fetchLeaderboardData = useCallback(
    async (week) => {
      // Add request deduplication
      const requestKey = `${week}-${viewMode}`;
      if (activeRequests.current.has(requestKey)) {
        console.log("Request already in progress for:", requestKey);
        return activeRequests.current.get(requestKey);
      }

      const request = (async () => {
        // Add null checks with meaningful fallbacks
        if (!memoizedUserPicks.length) {
          console.warn("User picks data unavailable, using empty dataset");
          setPicksError("Unable to load picks data");
          setIsLoading(false);
          setAvatarsLoaded(true);
          return;
        }

        if (!isUserLoaded) {
          console.log("Waiting for user data to load...");
          setIsLoading(false);
          setAvatarsLoaded(true);
          return;
        }

        // For total view, always fetch fresh data to ensure accuracy
        if (viewMode === "total") {
          setIsLoading(true);
          setIsDataReady(false);
          setAvatarsLoaded(false);
        } else {
          // Check cache for week view
          const cachedData = sessionStorage.getItem(
            `leaderboardData_week_${week}`
          );
          const lastKnownWeek = localStorage.getItem("lastKnownWeek");
          const currentWeekNumber = getCurrentWeek();

          // Clear cache if week has changed
          if (lastKnownWeek && parseInt(lastKnownWeek) !== currentWeekNumber) {
            sessionStorage.removeItem(`leaderboardData_week_${week}`);
          }

          if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            if (
              parsedData.timestamp &&
              Date.now() - parsedData.timestamp < 5 * 60 * 1000 &&
              (!lastKnownWeek || parseInt(lastKnownWeek) === currentWeekNumber)
            ) {
              // Use cached data without setting loading state
              const { rankedData, winner } = updateLeaderboard(
                parsedData.leaderboard,
                viewMode,
                calculateRanks,
                week,
                currentWeek,
                lastGameFinished
              );
              setLeaderboard(rankedData);
              setPicks(parsedData.picks);
              setWeeklyCorrectPicks(parsedData.weeklyCorrectPicks);
              setWeeklyWinner(winner);
              setIsDataReady(true);
              setIsLoading(false);
              setAvatarsLoaded(true);
              setArtificialDelay(false);
              return;
            }
          }
          setIsLoading(true);
          setIsDataReady(false);
          setAvatarsLoaded(false);
        }

        try {
          // First, update scores for all weeks up to current week
          await updateScores(week, viewMode === "total");

          // Initialize data structures
          const userCorrectPicks = {};
          const userWeeklyCorrectPicks = {};
          const picksMap = {};

          // Initialize data structures for all users
          memoizedUserPicks.forEach((pick) => {
            if (!userCorrectPicks[pick.username]) {
              userCorrectPicks[pick.username] = 0;
              userWeeklyCorrectPicks[pick.username] = {};
            }
          });

          // Batch fetch game data for all weeks
          const weekGamesCache = new Map();
          const weeksToProcess =
            viewMode === "total"
              ? Array.from({ length: currentWeek }, (_, i) => i + 1)
              : [week];

          // Pre-fetch all required game data at once and cache it
          const gamesPromises = weeksToProcess.map(async (weekNum) => {
            const cacheKey = `games_week_${weekNum}`;
            const cachedGames = sessionStorage.getItem(cacheKey);

            if (cachedGames) {
              const parsed = JSON.parse(cachedGames);
              if (Date.now() - parsed.timestamp < 60000) {
                // 1 minute cache
                weekGamesCache.set(weekNum, parsed.games);
                return;
              }
            }

            const games = getGamesForWeek(weekNum);
            weekGamesCache.set(weekNum, games);
            sessionStorage.setItem(
              cacheKey,
              JSON.stringify({
                games,
                timestamp: Date.now(),
              })
            );
          });

          await Promise.all(gamesPromises);

          // Rest of the processing code remains the same...
          for (let weekNum of weeksToProcess) {
            const weekPicks = memoizedUserPicks.filter(
              (pick) => parseInt(pick.week) === weekNum
            );
            const weekGames = weekGamesCache.get(weekNum);

            weekPicks.forEach((pick) => {
              const { username, picks: rawPicks, tiebreaker } = pick;
              userWeeklyCorrectPicks[username][weekNum] = 0;

              if (weekNum === week) {
                picksMap[username] = {
                  picks: parsePicks(pick),
                  tiebreaker,
                };
              }

              const parsedPicks = parsePicks(pick);

              Object.entries(parsedPicks).forEach(([gameIndex, gamePick]) => {
                const gameResult = weekGames[gameIndex];
                if (
                  gameResult &&
                  hasGameFinished(gameResult) &&
                  isPickCorrect(gamePick, gameResult)
                ) {
                  userWeeklyCorrectPicks[username][weekNum] += 1;
                  userCorrectPicks[username] += 1;
                }
              });
            });
          }

          // Create profile map with additional error checking
          const profileMap = memoizedProfiles.reduce((acc, profile) => {
            if (profile?.username) {
              acc[profile.username] = profile;
            }
            return acc;
          }, {});

          // Create leaderboard data
          const leaderboardData = Object.entries(userCorrectPicks)
            .filter(([username]) => username)
            .map(([username, totalCorrectPicks]) => {
              const userProfile = profileMap[username];
              return {
                username,
                totalCorrectPicks,
                weeklyCorrectPicks:
                  userWeeklyCorrectPicks[username]?.[week] || 0,
                tiebreaker: picksMap[username]?.tiebreaker || 0,
                avatarUrl: userProfile?.avatarUrl || null,
              };
            });

          const { rankedData, winner } = updateLeaderboard(
            leaderboardData,
            viewMode,
            calculateRanks,
            week,
            currentWeek,
            hasLastGameFinished(week)
          );

          if (viewMode !== "total") {
            sessionStorage.setItem(
              `leaderboardData_week_${week}`,
              JSON.stringify({
                leaderboard: rankedData,
                picks: picksMap,
                weeklyCorrectPicks: userWeeklyCorrectPicks,
                timestamp: Date.now(),
              })
            );
          }

          setLeaderboard(rankedData);
          setPicks(picksMap);
          setWeeklyCorrectPicks(userWeeklyCorrectPicks);
          setWeeklyWinner(winner);
          setIsDataReady(true);
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setError("Failed to fetch data. Please try again later.");
          setIsDataReady(false);
        } finally {
          setIsLoading(false);
          setAvatarsLoaded(true);
          setArtificialDelay(false);
        }
      })();

      // Store the request promise
      activeRequests.current.set(requestKey, request);

      try {
        await request;
      } finally {
        // Clean up the request tracking
        activeRequests.current.delete(requestKey);
      }
    },
    [
      viewMode,
      calculateRanks,
      currentWeek,
      memoizedUserPicks,
      memoizedProfiles,
      isUserLoaded,
      lastGameFinished,
    ]
  );

  // Modify the useEffect to use a cleanup function
  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      if (!memoizedUserPicks.length || !isUserLoaded) return;

      try {
        await fetchLeaderboardData(selectedWeek);
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      }
    };

    if (isSubscribed) {
      fetchData();
    }

    return () => {
      isSubscribed = false;
      // Clear any active requests for this week
      const requestKey = `${selectedWeek}-${viewMode}`;
      activeRequests.current.delete(requestKey);
    };
  }, [selectedWeek, memoizedUserPicks.length, isUserLoaded, viewMode]);

  useEffect(() => {
    log("Updating leaderboard based on view mode change", viewMode);
    const cachedData = sessionStorage.getItem(
      `leaderboardData_week_${selectedWeek}`
    );
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      const { rankedData, winner } = updateLeaderboard(
        parsedData.leaderboard,
        viewMode,
        calculateRanks,
        selectedWeek,
        currentWeek,
        lastGameFinished
      );

      // Preserve avatar URLs when updating leaderboard
      const updatedLeaderboard = rankedData.map((row) => ({
        ...row,
        avatarUrl: row.avatarUrl,
      }));

      setLeaderboard(updatedLeaderboard);
      setWeeklyWinner(winner);
    }
  }, [viewMode, selectedWeek, calculateRanks, currentWeek, lastGameFinished]);

  const handleRowClick = useCallback((username) => {
    setExpandedRows((prevRows) => {
      if (prevRows.includes(username)) {
        return prevRows.filter((user) => user !== username);
      } else {
        return [...prevRows, username];
      }
    });
  }, []);

  useEffect(() => {}, [expandedRows]);

  const handleWeekChange = (newWeek) => {
    if (
      (viewMode === "week" || viewMode === "compare") &&
      isWeekAvailable(newWeek) &&
      newWeek !== selectedWeek
    ) {
      // Check if we have valid cached data before showing loading state
      const cachedData = sessionStorage.getItem(
        `leaderboardData_week_${newWeek}`
      );
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        if (
          parsedData.timestamp &&
          Date.now() - parsedData.timestamp < 5 * 60 * 1000
        ) {
          // Use cached data without loading state
          setSelectedWeek(newWeek);
          setExpandedRows([]);
          const { rankedData, winner } = updateLeaderboard(
            parsedData.leaderboard,
            viewMode,
            calculateRanks,
            newWeek,
            currentWeek,
            lastGameFinished
          );
          setLeaderboard(rankedData);
          setPicks(parsedData.picks);
          setWeeklyCorrectPicks(parsedData.weeklyCorrectPicks);
          setWeeklyWinner(winner);
          setIsLoading(false);
          setAvatarsLoaded(true);
          setArtificialDelay(false);
          return;
        }
      }

      // Only show loading state if we need to fetch new data
      setIsLoading(true);
      setIsDataReady(false);
      setArtificialDelay(true);
      setAvatarsLoaded(false);
      setSelectedWeek(newWeek);
      setExpandedRows([]);
      setPicks({});

      // Fetch new data with minimal delay
      setTimeout(() => {
        setArtificialDelay(false);
        fetchLeaderboardData(newWeek);
      }, 300);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderPicks = useCallback(
    (picksData) => {
      if (!picksData || !picksData.picks) {
        return null;
      }

      const picks = picksData.picks;
      const tiebreaker = picksData.tiebreaker;
      const weekGames = getGamesForWeek(selectedWeek);

      return (
        <div className="p-4 rounded-lg">
          <h3 className="text-lg font-bold text-blue-300 mb-4 text-center">
            Week {selectedWeek} Picks
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            {weekGames.map((gameData, i) => {
              const pick = picks[i];
              const gameStarted = hasGameStarted(gameData.startTime);
              const gameFinished = hasGameFinished(gameData);

              if (!gameStarted) {
                return (
                  <div
                    key={i}
                    className="bg-gray-700 rounded-lg p-3 flex items-center justify-between"
                  >
                    <span className="text-sm font-medium text-gray-400">
                      Game not started
                    </span>
                    <Lock className="h-4 w-4 text-gray-400" />
                  </div>
                );
              }

              if (!pick || pick === "Not Submitted") {
                return (
                  <div
                    key={i}
                    className="bg-gray-700 rounded-lg p-3 flex items-center justify-between border-l-4 border-yellow-500"
                  >
                    <span className="text-sm font-medium text-yellow-400">
                      Not Submitted
                    </span>
                  </div>
                );
              }

              let [teamAbbr, spread] = pick.split(" ");
              spread = spread.replace(/[()]/g, "");
              const team =
                gameData.away.abbreviation === teamAbbr
                  ? gameData.away
                  : gameData.home;

              const isCorrect = gameFinished && isPickCorrect(pick, gameData);
              const isPush = gameFinished && gameData.winner === "PUSH";
              const isNull = !gameData.winner;

              return (
                <div
                  key={i}
                  className={`bg-gray-700 rounded-lg p-3 flex items-center justify-between ${
                    isNull || !gameFinished
                      ? "border-gray-600"
                      : isPush
                        ? "border-yellow-500"
                        : isCorrect
                          ? "border-green-500"
                          : "border-red-500"
                  } border-l-4`}
                >
                  <div className="flex items-center space-x-3">
                    <img
                      src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${teamAbbr.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=100&h=100`}
                      alt={team.name}
                      className="w-8 h-8"
                    />
                    <span className="text-sm font-medium text-gray-200">
                      {team.name}
                    </span>
                  </div>
                  <span className="text-sm font-semibold text-blue-300">
                    {spread}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="mt-4 bg-blue-900 rounded-lg p-3 flex justify-between items-center">
            <span className="text-sm font-semibold text-blue-200">
              Tiebreaker
            </span>
            <span
              className={`text-lg font-bold text-blue-100 ${
                !lastGameStarted ? "blur-md" : ""
              }`}
            >
              {tiebreaker}
            </span>
          </div>
        </div>
      );
    },
    [selectedWeek, lastGameStarted]
  );

  useEffect(() => {
    const updateCurrentWeek = () => {
      const newCurrentWeek = getCurrentWeek();
      if (newCurrentWeek !== currentWeekRef.current) {
        currentWeekRef.current = newCurrentWeek;
        setCurrentWeek(newCurrentWeek);
        // Only update selectedWeek if we're viewing the current week
        if (selectedWeekRef.current === currentWeek) {
          setSelectedWeek(newCurrentWeek);
        }
      }
    };

    updateCurrentWeek(); // Initial check
    const interval = setInterval(updateCurrentWeek, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []); // Remove currentWeek dependency

  useEffect(() => {
    if (isDropdownOpen && currentWeekRef.current) {
      currentWeekRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    const checkLastGameStarted = () => {
      const hasStarted = hasLastGameStarted(selectedWeek);
      setLastGameStarted(hasStarted);
    };

    checkLastGameStarted();
    const interval = setInterval(checkLastGameStarted, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [selectedWeek]);

  useEffect(() => {
    const checkLastGameFinished = () => {
      const hasFinished = hasLastGameFinished(selectedWeek);
      setLastGameFinished(hasFinished);
    };

    // Run immediately
    checkLastGameFinished();

    // Then set up interval
    const interval = setInterval(checkLastGameFinished, 60000);

    return () => clearInterval(interval);
  }, [selectedWeek]); // Only depend on selectedWeek

  useEffect(() => {
    setShowRouteSkeleton(true);
    const timer = setTimeout(() => {
      setShowRouteSkeleton(false);
    }, 500); // Adjust this value to control how long the skeleton is shown

    return () => clearTimeout(timer);
  }, [location]);

  useEffect(() => {
    localStorage.setItem("leaderboardViewMode", viewMode);
  }, [viewMode]);

  useEffect(() => {
    if (user?.imageUrl && isUserLoaded && user?.username) {
      updateUserMetadata(user, user.imageUrl).catch(console.error);
    }
  }, [user?.imageUrl, isUserLoaded, user?.username]);

  useEffect(() => {
    let refreshInterval;
    const refreshLeaderboard = async () => {
      // Only refresh if we're viewing the current week or total view
      if ((viewMode === "total" || selectedWeek === currentWeek) && !activeRequests.current.size) {
        try {
          await fetchLeaderboardData(selectedWeek);
        } catch (error) {
          console.error('Error refreshing leaderboard:', error);
        }
      }
    };

    // Determine refresh interval based on game status
    if (lastGameStarted && !lastGameFinished) {
      // Games in progress - refresh every minute
      refreshInterval = setInterval(refreshLeaderboard, 60000);
    } else if (lastGameFinished) {
      // Games finished - refresh every 5 minutes
      refreshInterval = setInterval(refreshLeaderboard, 300000);
    }

    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, [selectedWeek, currentWeek, viewMode, lastGameStarted, lastGameFinished]); // Remove fetchLeaderboardData from dependencies

  const WeekSelector = () => {
    const weeks = Object.keys(weekDates).map(Number);
    const isDisabled = viewMode === "total";
    const [open, setOpen] = React.useState(false);

    return (
      <div className="flex items-center justify-center space-x-4 mb-6">
        <Button
          variant="base"
          size="icon"
          onClick={() => handleWeekChange(selectedWeek - 1)}
          disabled={isDisabled || !isWeekAvailable(selectedWeek - 1)}
          className="h-10 w-10"
        >
          <ChevronLeft className="h-5 w-5 text-gray-300" />
        </Button>

        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="base"
              role="combobox"
              disabled={isDisabled}
              className={cn(
                "w-[200px] h-10 text-base justify-between",
                !isWeekAvailable(selectedWeek) && "text-muted-foreground"
              )}
            >
              <span className="flex items-center gap-2 text-gray-300">
                <Calendar className="h-5 w-5" />
                Week {selectedWeek}
              </span>
              <ChevronDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0 bg-gray-800 border-gray-700">
            <div className="max-h-[300px] overflow-y-auto">
              {weeks.map((week) => (
                <button
                  key={week}
                  onClick={() => {
                    handleWeekChange(week);
                    setOpen(false);
                  }}
                  disabled={!isWeekAvailable(week)}
                  className={cn(
                    "w-full flex items-center justify-between px-4 py-3 text-base text-gray-300 lg:hover:bg-gray-700 disabled:text-gray-500 disabled:hover:bg-transparent",
                    !isWeekAvailable(week) && "text-gray-500",
                    selectedWeek === week && "bg-gray-700"
                  )}
                >
                  <span>Week {week}</span>
                  {selectedWeek === week && (
                    <Check className="h-5 w-5 text-blue-400" />
                  )}
                </button>
              ))}
            </div>
          </PopoverContent>
        </Popover>

        <Button
          variant="base"
          size="icon"
          onClick={() => handleWeekChange(selectedWeek + 1)}
          disabled={isDisabled || !isWeekAvailable(selectedWeek + 1)}
          className="h-10 w-10 "
        >
          <ChevronRight className="h-5 w-5 text-gray-300" />
        </Button>
      </div>
    );
  };

  // Modify the Avatar component usage to use cached URLs
  const renderAvatar = (username, avatarUrl) => (
    <Avatar className="h-12 w-12 sm:h-14 sm:w-14 rounded-full bg-gray-700 border-2 border-blue-500 shadow-md overflow-hidden">
      <AvatarImage
        src={avatarCache.get(username) || avatarUrl}
        alt={username}
        className="w-full h-full object-cover"
        loading="eager"
      />
      {!avatarCache.get(username) && !avatarUrl && (
        <AvatarFallback className="flex items-center justify-center text-lg font-bold text-white">
          {username.charAt(0).toUpperCase()}
        </AvatarFallback>
      )}
    </Avatar>
  );

  // Update the avatar rendering in the leaderboard map
  {
    leaderboard.map((row, index) => (
      <div key={index} className="...">
        <div className="...">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3 sm:space-x-4">
              <div className="relative">
                {renderAvatar(row.username, row.avatarUrl)}
              </div>
              <div>
                <h2 className="font-bold text-base sm:text-lg text-blue-300 truncate max-w-[150px] sm:max-w-[200px]">
                  {row.username}
                </h2>
                <p className="text-xs sm:text-sm text-gray-400 flex items-center mt-0.5">
                  <Trophy className="w-3 h-3 mr-1 text-yellow-500" />
                  Rank: {row.rank}
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4 sm:space-x-6">
              <div className="flex flex-col items-center">
                <span className="text-xs sm:text-sm text-gray-400 mb-0.5 flex items-center">
                  {viewMode === "total" ? (
                    <>
                      <Target className="w-3 h-3 mr-1 text-green-400" />
                      Total
                    </>
                  ) : (
                    <>
                      <TrendingUp className="w-3 h-3 mr-1 text-blue-400" />
                      Week
                    </>
                  )}
                </span>
                <span
                  className={`font-semibold text-base sm:text-lg ${
                    viewMode === "total" ? "text-green-400" : "text-blue-400"
                  }`}
                >
                  {viewMode === "total"
                    ? row.totalCorrectPicks
                    : weeklyCorrectPicks[row.username]?.[selectedWeek] || 0}
                </span>
              </div>
              <div className="text-blue-400 hover:text-blue-300 transition-colors duration-200">
                {expandedRows.includes(row.username) ? (
                  <ChevronUp size={20} />
                ) : (
                  <ChevronDown size={20} />
                )}
              </div>
            </div>
          </div>
        </div>
        {expandedRows.includes(row.username) && (
          <div className="px-3 sm:px-4 pb-3 sm:pb-4">
            {picks[row.username] ? (
              renderPicks(picks[row.username])
            ) : (
              <div className="text-center text-red-400 bg-red-900 bg-opacity-20 rounded-lg p-2 text-xs sm:text-sm">
                {row.username} has not made picks yet for week {selectedWeek}.
              </div>
            )}
          </div>
        )}
      </div>
    ));
  }

  // Update the component's return statement to handle loading states better
  if (!isUserLoaded || (!allUserPicks && !picksError)) {
    return (
      <div className="text-gray-100 min-h-screen">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-6 max-w-3xl relative z-0">
          <WeekSelector />
          <ViewModeSwitch
            viewMode={viewMode}
            setViewMode={setViewMode}
            setViewTransitioning={setViewTransitioning}
          />
          <div className="space-y-3 sm:space-y-4 mt-4 sm:mt-6">
            {[...Array(10)].map((_, index) => (
              <SkeletonRow key={index} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  // Show error state if picks failed to load
  if (picksError) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="bg-red-900 bg-opacity-50 border border-red-700 rounded-lg p-6">
          <h2 className="text-2xl font-bold text-red-300 mb-3">Error</h2>
          <p className="text-lg text-red-100">{picksError}</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="bg-red-900 bg-opacity-50 border border-red-700 rounded-lg p-6">
          <h2 className="text-2xl font-bold text-red-300 mb-3">Error</h2>
          <p className="text-lg text-red-100">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="text-gray-100 min-h-screen">
      <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-6 max-w-3xl relative z-0">
        <WeekSelector />
        <ViewModeSwitch
          viewMode={viewMode}
          setViewMode={setViewMode}
          setViewTransitioning={setViewTransitioning}
        />

        <div className="relative mt-4 sm:mt-6">
          {(isLoading ||
            !isDataReady ||
            artificialDelay ||
            viewTransitioning) && (
            <div className="absolute inset-0 z-10 transition-opacity duration-300">
              <div className="space-y-3 sm:space-y-4">
                {[...Array(10)].map((_, index) => (
                  <SkeletonRow key={index} />
                ))}
              </div>
            </div>
          )}

          <div
            className={`space-y-3 sm:space-y-4 ${
              isLoading || !isDataReady || artificialDelay || viewTransitioning
                ? "invisible"
                : "visible"
            }`}
          >
            {viewMode === "compare" ? (
              <ComparisonView
                leaderboard={leaderboard}
                picks={picks}
                selectedWeek={selectedWeek}
                lastGameStarted={lastGameStarted}
              />
            ) : (
              <div className="space-y-3 sm:space-y-4 mt-4 sm:mt-6">
                {leaderboard.map((row, index) => (
                  <div
                    key={index}
                    className={`bg-gray-800 rounded-xl overflow-hidden shadow-lg  ${
                      viewMode === "week" &&
                      row.username === weeklyWinner &&
                      (selectedWeek < currentWeek ||
                        hasLastGameFinished(selectedWeek))
                        ? "border-2 border-yellow-500 shadow-yellow-500/50"
                        : expandedRows.includes(row.username)
                          ? "border-2 border-blue-500 shadow-blue-500/50"
                          : "border border-gray-700 shadow-gray-900/50"
                    } hover:shadow-xl`}
                    style={{
                      boxShadow:
                        "0 10px 10px -3px rgba(0, 0, 0, 0.5), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <div
                      className="p-3 sm:p-4 cursor-pointer"
                      onClick={() => handleRowClick(row.username)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-3 sm:space-x-4">
                          <div className="relative">
                            {renderAvatar(row.username, row.avatarUrl)}
                          </div>
                          <div>
                            <h2 className="font-bold text-base sm:text-lg text-blue-300 truncate max-w-[150px] sm:max-w-[200px]">
                              {row.username}
                            </h2>
                            <p className="text-xs sm:text-sm text-gray-400 flex items-center mt-0.5">
                              <Trophy className="w-3 h-3 mr-1 text-yellow-500" />
                              Rank: {row.rank}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center space-x-4 sm:space-x-6">
                          <div className="flex flex-col items-center">
                            <span className="text-xs sm:text-sm text-gray-400 mb-0.5 flex items-center">
                              {viewMode === "total" ? (
                                <>
                                  <Target className="w-3 h-3 mr-1 text-green-400" />
                                  Total
                                </>
                              ) : (
                                <>
                                  <TrendingUp className="w-3 h-3 mr-1 text-blue-400" />
                                  Week
                                </>
                              )}
                            </span>
                            <span
                              className={`font-semibold text-base sm:text-lg ${
                                viewMode === "total"
                                  ? "text-green-400"
                                  : "text-blue-400"
                              }`}
                            >
                              {viewMode === "total"
                                ? row.totalCorrectPicks
                                : weeklyCorrectPicks[row.username]?.[
                                    selectedWeek
                                  ] || 0}
                            </span>
                          </div>
                          <div className="text-blue-400 hover:text-blue-300 transition-colors duration-200">
                            {expandedRows.includes(row.username) ? (
                              <ChevronUp size={20} />
                            ) : (
                              <ChevronDown size={20} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {expandedRows.includes(row.username) && (
                      <div className="px-3 sm:px-4 pb-3 sm:pb-4">
                        {picks[row.username] ? (
                          renderPicks(picks[row.username])
                        ) : (
                          <div className="text-center text-red-400 bg-red-900 bg-opacity-20 rounded-lg p-2 text-xs sm:text-sm">
                            {row.username} has not made picks yet for week{" "}
                            {selectedWeek}.
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardPage;
