import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CheckCircle, XCircle, Edit, Loader2 } from "lucide-react";
import Modal from "./Modal";
import {
  currentWeek,
  getGamesForWeek,
  getWinnersForWeek,
  isPickCorrect,
  updateScores,
  GAME_STATUS,
  getGameStatus,
} from "../gameData.js";
import { isWeekViewable, weekDates } from "./weekDates";
import { PicksLoader } from "./PicksLoader";
import styled, { keyframes } from "styled-components";
import { Button } from "./button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { useUser } from "@clerk/clerk-react";

const pulse = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const LiveText = styled.span`
  font-weight: 600;
  color: #ef4444;
  transition: all 0.2s ease;
`;

const LiveDot = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ef4444;
  margin-right: 6px;
  animation: ${pulse} 2s ease-in-out infinite;
`;

const LiveContainer = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 12px;
  background-color: rgba(239, 68, 68, 0.1);
  transition: all 0.2s ease;
  &:hover {
    background-color: rgba(239, 68, 68, 0.2);
    ${LiveText} {
      color: #dc2626;
    }
  }
`;

function TiebreakerInput({
  tiebreakerValue,
  onChange,
  isFormEditable,
  isEditing,
}) {
  const [inputValue, setInputValue] = useState(tiebreakerValue);

  useEffect(() => {
    setInputValue(tiebreakerValue);
  }, [tiebreakerValue]);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setInputValue(value);
  };

  const handleBlur = () => {
    onChange(inputValue);
  };

  const isValid =
    inputValue === "" ||
    (!isNaN(parseInt(inputValue, 10)) && parseInt(inputValue, 10) >= 0);
  const showError = !isValid;

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center space-x-3">
        <Input
          name="tiebreaker"
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="41"
          className={`max-w-[120px] bg-gray-900/50 border-gray-600 text-gray-100 placeholder:text-gray-500 ${
            showError ? "border-red-500 ring-red-500" : ""
          }`}
          disabled={!isFormEditable && !isEditing}
          required
        />
        <span className="text-sm font-medium text-gray-400">
          total points <span className="text-red-500">*</span>
        </span>
      </div>
      {showError && (
        <span className="text-sm text-red-500">
          Please enter a valid score (must be 0 or greater)
        </span>
      )}
    </div>
  );
}

function GameCard({
  game,
  index,
  handlePickClick,
  selectedPicks,
  editablePicks,
  getDisplayStatus,
  getPickStatus,
}) {
  const [countdown, setCountdown] = useState("");
  const [showCountdown, setShowCountdown] = useState(false);

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const gameStart = new Date(game.startTime);
      const timeUntilStart = gameStart - now;
      const oneHourInMs = 60 * 60 * 1000;

      if (timeUntilStart <= oneHourInMs && timeUntilStart > 0) {
        const minutes = Math.floor(
          (timeUntilStart % oneHourInMs) / (60 * 1000)
        );
        const seconds = Math.floor((timeUntilStart % (60 * 1000)) / 1000);
        setCountdown(`${minutes}:${seconds.toString().padStart(2, "0")}`);
        setShowCountdown(true);
      } else {
        setCountdown("");
        setShowCountdown(false);
      }
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [game.startTime]);

  const pickStatus = selectedPicks[index]
    ? getPickStatus(index, selectedPicks[index])
    : null;
  const gameStatus = getDisplayStatus(game);
  const isLive = gameStatus !== "FINAL" && gameStatus !== "";
  const isCompleted = gameStatus === "FINAL";
  const isUpcoming = gameStatus === "";

  return (
    <Card
      data-game-index={index}
      className={`
        ${!editablePicks[index] ? "opacity-75" : ""}
        hover:shadow-lg transition-shadow duration-200
        ${editablePicks[index] && !selectedPicks[index] ? "ring-2 ring-red-500/50" : ""}
        ${isLive ? "backdrop-blur-sm bg-gray-800/50 cursor-not-allowed" : ""}
      `}
    >
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <div className="flex flex-col">
          <CardTitle className="text-lg font-bold tracking-tight">
            {game.away.abbreviation} @ {game.home.abbreviation}
          </CardTitle>
          <span className="text-sm text-muted-foreground mt-0.5">
            {new Date(game.startTime).toLocaleString("en-US", {
              weekday: "short",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
              hour12: true,
              timeZone: "America/New_York",
            }) + " ET"}
          </span>
        </div>
        {isCompleted && (
          <Badge variant="secondary" className="px-2.5 py-0.5 font-medium">
            FINAL
          </Badge>
        )}
        {isLive && (
          <Badge
            variant="destructive"
            className="px-2.5 py-0.5 bg-transparent hover:bg-transparent"
          >
            <div className="flex items-center gap-1.5">
              <span className="relative flex h-2 w-2">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
              </span>
              <span className="font-medium">{gameStatus}</span>
            </div>
          </Badge>
        )}
        {isUpcoming && (
          <Badge
            variant="secondary"
            className="px-2.5 py-0.5 font-medium min-w-[100px] flex justify-center"
          >
            {showCountdown ? (
              <div className="flex items-center gap-1.5">
                <span className="relative flex h-2 w-2">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
                </span>
                <span className="font-medium text-yellow-400 w-[45px] text-center">
                  {countdown}
                </span>
              </div>
            ) : (
              <span>UPCOMING</span>
            )}
          </Badge>
        )}
      </CardHeader>
      <CardContent className="space-y-2.5">
        {[game.away, game.home].map((team, teamIndex) => {
          const isSelected = selectedPicks[index]?.includes(team.abbreviation);
          const shouldShowResult = isCompleted && isSelected;
          return (
            <div key={teamIndex} className="relative w-full">
              <button
                type="button"
                onClick={() =>
                  handlePickClick(index, teamIndex === 0 ? "away" : "home")
                }
                disabled={!editablePicks[index]}
                className={`
                  w-full text-left flex items-center justify-between p-3.5 rounded-lg
                  ${
                    shouldShowResult
                      ? pickStatus === "correct"
                        ? "bg-green-900/40 border-green-500"
                        : "bg-red-900/40 border-red-500"
                      : isSelected
                        ? "bg-blue-900/40 border-blue-500 border-2 ring-2 ring-blue-500/50"
                        : "bg-gray-700/50 border border-gray-600"
                  }
                  ${!editablePicks[index] ? "cursor-not-allowed opacity-90" : "cursor-pointer"}
                `}
              >
                <div className="flex items-center space-x-4">
                  <img
                    src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${team.abbreviation.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=100&h=100`}
                    alt={team.abbreviation}
                    className="w-11 h-11"
                  />
                  <div className="flex flex-col">
                    <span className="font-semibold text-gray-100">
                      {team.name}
                    </span>
                    <span className="text-xs text-gray-400 mt-0.5">
                      {team.record || "N/A"}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <span
                    className={`font-bold text-lg ${
                      team.spread > 0 ? "text-red-400" : "text-green-400"
                    }`}
                  >
                    {team.spread > 0 ? "+" : ""}
                    {team.spread}
                  </span>
                </div>
              </button>
            </div>
          );
        })}
      </CardContent>
      {isCompleted && (
        <CardFooter className="flex flex-col space-y-2 bg-gray-800/30 px-4 py-3 rounded-b-lg">
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col space-y-1.5">
              <span className="text-sm text-gray-400">Final Score</span>
              <div className="flex items-center space-x-3">
                <div className="flex items-center">
                  <span className="font-medium text-sm text-gray-400 mr-2">
                    {game.away.abbreviation}
                  </span>
                  <span className="font-semibold text-lg">
                    {game.awayScore}
                  </span>
                </div>
                <span className="text-gray-500">-</span>
                <div className="flex items-center">
                  <span className="font-semibold text-lg">
                    {game.homeScore}
                  </span>
                  <span className="font-medium text-sm text-gray-400 ml-2">
                    {game.home.abbreviation}
                  </span>
                </div>
              </div>
            </div>
            {selectedPicks[index] ? (
              pickStatus === "correct" ? (
                <Badge
                  variant="success"
                  className="text-xs px-2.5 py-0.5 bg-green-600 hover:bg-green-700"
                >
                  Won
                </Badge>
              ) : (
                <Badge variant="destructive" className="text-xs px-2.5 py-0.5">
                  Lost
                </Badge>
              )
            ) : (
              <Badge variant="destructive" className="text-xs px-2.5 py-0.5">
                Lost
              </Badge>
            )}
          </div>
        </CardFooter>
      )}
    </Card>
  );
}

const isWeekInSubmissionPeriod = (weekNumber) => {
  const now = new Date();
  const weekDate = weekDates[weekNumber];
  return (
    weekDate &&
    now >= new Date(weekDate.availableFrom) &&
    now < new Date(weekDate.viewableFrom)
  );
};

function HomePage({ username, setIsScrollable, setHideBottomNav, setHasSubmittedAllPicks }) {
  const [isUploading, setIsUploading] = useState(false);
  const [selectedPicks, setSelectedPicks] = useState({});
  const [originalPicks, setOriginalPicks] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const [hasSubmittedPicks, setHasSubmittedPicks] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tiebreaker, setTiebreaker] = useState("");
  const [originalTiebreaker, setOriginalTiebreaker] = useState("");
  const [gameScores, setGameScores] = useState({});
  const [isFormEditable, setIsFormEditable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { user } = useUser();
  const [unselectedGames, setUnselectedGames] = useState({});
  const activeRequests = useRef(new Map());
  const gamesCache = useRef(new Map());

  const teams = useMemo(() => getGamesForWeek(currentWeek), []);

  const memoizedGetUnselectedGames = useCallback(
    (userPicks) => {
      return teams.filter((game, index) => {
        const gameStart = new Date(game.startTime);
        const now = new Date();
        return now < gameStart && (!userPicks || !userPicks[index]);
      });
    },
    [teams]
  );

  const getDisplayStatus = useCallback((game) => {
    const status = getGameStatus(game);
    if (status === GAME_STATUS.COMPLETED) return "FINAL";
    if (status === GAME_STATUS.UPCOMING) return "";
    if (game.clock && game.period) {
      return `Q${game.period} ${game.clock}`;
    }
    return "LIVE";
  }, []);

  const getInitialEditablePicks = useCallback(() => {
    const initialPicks = {};
    const now = new Date();
    teams.forEach((game, index) => {
      const gameStart = new Date(game.startTime);
      initialPicks[index] = now < gameStart;
    });
    return initialPicks;
  }, [teams]);

  const modalContent = useMemo(
    () => ({
      title: "",
      message: "",
      type: "",
    }),
    []
  );

  const clearSessionStorage = () => {
    for (let i = 1; i <= 18; i++) {
      sessionStorage.removeItem(`leaderboardData_week_${i}`);
      localStorage.removeItem(`gamesWeek${i}`);
    }
    sessionStorage.removeItem(`profileData_${username}`);
    localStorage.removeItem("lastFetchTime");
  };

  const getUserWeekPicks = useQuery(api.picks.getUserWeekPicks, {
    username: username || "",
    week: currentWeek.toString(),
  });

  const fetchUserData = useCallback(async () => {
    const requestKey = `userData-${username}-${currentWeek}`;
    if (activeRequests.current.has(requestKey)) {
      return activeRequests.current.get(requestKey);
    }

    if (!isFirstFetch && getUserWeekPicks === undefined) {
      return;
    }

    const request = (async () => {
      if (getUserWeekPicks === undefined) {
        setIsLoading(true);
        return;
      }

      setIsLoading(true);
      try {
        if (!user) throw new Error("No user logged in");
        const uname = user.username;
        if (!uname) throw new Error("No username found");

        const hasSubmitted = getUserWeekPicks && getUserWeekPicks.length > 0;

        if (hasSubmitted) {
          const data = getUserWeekPicks[0];
          let picks = {};
          try {
            picks =
              typeof data.picks === "string"
                ? JSON.parse(data.picks)
                : data.picks;
          } catch (parseError) {
            // If parse fails, consider them empty picks
          }

          const hasValidPicks =
            Object.keys(picks).length > 0 &&
            Object.values(picks).some((pick) => pick !== "Not Submitted");

          if (hasValidPicks) {
            setSelectedPicks(picks);
            setTiebreaker(data.tiebreaker || "");
            setIsFormEditable(false);
            setHasSubmittedPicks(true);
            setUnselectedGames((prev) => ({
              ...prev,
              [username]: memoizedGetUnselectedGames(picks),
            }));
          } else {
            setSelectedPicks({});
            setTiebreaker("");
            setIsFormEditable(true);
            setHasSubmittedPicks(false);
            setUnselectedGames((prev) => ({
              ...prev,
              [username]: memoizedGetUnselectedGames(null),
            }));
          }
        } else {
          setSelectedPicks({});
          setTiebreaker("");
          setIsFormEditable(true);
          setHasSubmittedPicks(false);
          setUnselectedGames((prev) => ({
            ...prev,
            [username]: memoizedGetUnselectedGames(null),
          }));
        }

        setIsEditing(false);
        setOriginalPicks({});
        setOriginalTiebreaker("");
      } catch (error) {
        toast.error("Failed to load user data. Please try again.", {
          icon: <XCircle className="text-red-500" />,
          className: "Toastify__toast--dark",
        });
      } finally {
        setIsLoading(false);
        setIsFirstFetch(false);
      }
    })();

    activeRequests.current.set(requestKey, request);
    await request;
    activeRequests.current.delete(requestKey);
  }, [
    getUserWeekPicks,
    user,
    username,
    isFirstFetch,
    memoizedGetUnselectedGames,
  ]);

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        if (mounted) {
          if (getUserWeekPicks !== undefined || isFirstFetch) {
            await fetchUserData();
          }
        }
      } catch (error) {
        if (mounted) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [getUserWeekPicks, fetchUserData, username, currentWeek, isFirstFetch]);

  // Fetching Scores
  useEffect(() => {
    const fetchScores = async () => {
      const cacheKey = `scores-${currentWeek}`;
      const now = Date.now();
      const cachedData = gamesCache.current.get(cacheKey);

      if (cachedData && now - cachedData.timestamp < 60000) {
        setGameScores(cachedData.scores);
        return;
      }

      if (activeRequests.current.has(cacheKey)) {
        return activeRequests.current.get(cacheKey);
      }

      const request = (async () => {
        await updateScores(currentWeek);
        const allGames = getGamesForWeek(currentWeek);
        const scores = {};
        allGames.forEach((game, index) => {
          if (game.homeScore !== undefined && game.awayScore !== undefined) {
            scores[index] = {
              score: `${game.away.abbreviation} ${game.awayScore} - ${game.homeScore} ${game.home.abbreviation}`,
              clock: game.clock,
              period: game.period,
            };
          }
        });
        gamesCache.current.set(cacheKey, {
          scores,
          timestamp: now,
        });
        setGameScores(scores);
      })();

      activeRequests.current.set(cacheKey, request);
      await request;
      activeRequests.current.delete(cacheKey);
    };

    fetchScores();
    const interval = setInterval(fetchScores, 60000);

    return () => {
      clearInterval(interval);
      activeRequests.current.clear();
      gamesCache.current.clear();
    };
  }, [currentWeek]);

  // Check if picks are editable
  const [editablePicks, setEditablePicks] = useState(getInitialEditablePicks());
  useEffect(() => {
    const checkGameStatus = () => {
      const now = new Date();
      setEditablePicks((prevPicks) => {
        const newPicks = { ...prevPicks };
        let hasChanges = false;
        teams.forEach((game, index) => {
          const gameStart = new Date(game.startTime);
          const shouldBeEditable = now < gameStart;
          if (newPicks[index] !== shouldBeEditable) {
            newPicks[index] = shouldBeEditable;
            hasChanges = true;
          }
        });
        return hasChanges ? newPicks : prevPicks;
      });
    };
    checkGameStatus();
    const interval = setInterval(checkGameStatus, 5000);
    return () => clearInterval(interval);
  }, [teams]);

  const handlePickClick = useCallback(
    (gameIndex, teamType) => {
      const game = teams[gameIndex];
      const now = new Date();
      const gameStart = new Date(game.startTime);

      if (now >= gameStart || !isFormEditable || !editablePicks[gameIndex])
        return;

      const team = teamType === "away" ? game.away : game.home;
      const pick = `${team.abbreviation} (${team.spread > 0 ? "+" : ""}${team.spread})`;

      setSelectedPicks((prevPicks) => {
        const newPicks = { ...prevPicks };
        if (newPicks[gameIndex] === pick) {
          delete newPicks[gameIndex];
        } else {
          newPicks[gameIndex] = pick;
        }
        return newPicks;
      });
    },
    [teams, isFormEditable, editablePicks]
  );

  const updateUserWeekPicks = useMutation(api.picks.updateUserWeekPicks);

  const sendPicksToConvex = useCallback(
    async (picks, week, tiebreakerValue) => {
      if (!user) throw new Error("No user logged in");
      if (!username) throw new Error("Unable to determine username");

      const picksString = JSON.stringify(picks);
      const picksData = [
        {
          picks: picksString,
          tiebreaker: tiebreakerValue,
          user_id: user.id,
          username: username,
          week: week.toString(),
          created_at: new Date().toISOString(),
          id: crypto.randomUUID(),
        },
      ];
      await updateUserWeekPicks({
        username,
        week: week.toString(),
        picks: picksData,
      });
      return true;
    },
    [user, username, updateUserWeekPicks]
  );

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const resetViewport = () => {
    window.scrollTo(0, 0);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (isSubmitting) return;

      const hasValidPicks =
        Object.keys(selectedPicks).length > 0 &&
        Object.values(selectedPicks).some((pick) => pick !== "Not Submitted");

      const tiebreakerValue = parseInt(tiebreaker, 10);
      const isValidTiebreaker = !isNaN(tiebreakerValue) && tiebreakerValue >= 0;

      if (!hasValidPicks) {
        toast.error("Please make at least one pick before submitting.", {
          icon: <XCircle className="w-5 h-5 text-red-500" />,
          style: { background: "rgba(239, 68, 68, 0.15)" },
          progressStyle: {
            background: "linear-gradient(to right, #ef4444, #f87171)",
          },
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      if (!isValidTiebreaker) {
        toast.error("Please enter a valid tiebreaker score.", {
          icon: <XCircle className="w-5 h-5 text-red-500" />,
          style: { background: "rgba(239, 68, 68, 0.15)" },
          progressStyle: {
            background: "linear-gradient(to right, #ef4444, #f87171)",
          },
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      const picksToSubmit = { ...selectedPicks };
      teams.forEach((game, index) => {
        if (!editablePicks[index] && !picksToSubmit[index]) {
          picksToSubmit[index] = "Not Submitted";
        }
      });

      setIsSubmitting(true);
      setIsUploading(true);
      try {
        await sendPicksToConvex(picksToSubmit, currentWeek, tiebreaker);
        setHasSubmittedPicks(true);
        setIsEditing(false);
        setIsFormEditable(false);
        setSelectedPicks(picksToSubmit);
        toast.success(
          `Your picks for Week ${currentWeek} have been ${
            isEditing ? "updated" : "recorded"
          }. Good luck!`,
          {
            icon: <CheckCircle className="w-5 h-5 text-emerald-500" />,
            style: { background: "rgba(16, 185, 129, 0.15)" },
            progressStyle: {
              background: "linear-gradient(to right, #10b981, #34d399)",
            },
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            delay: 0,
          }
        );
        resetViewport();
      } catch {
        toast.error(
          `Error ${isEditing ? "updating" : "submitting"} picks. Please try again.`,
          {
            icon: <XCircle className="text-red-500" />,
            className: "Toastify__toast--dark",
          }
        );
      } finally {
        setIsUploading(false);
        setIsSubmitting(false);
      }
    },
    [
      isSubmitting,
      selectedPicks,
      tiebreaker,
      isEditing,
      teams,
      editablePicks,
      sendPicksToConvex,
      currentWeek,
    ]
  );

  const handleEdit = useCallback(() => {
    const newEditablePicks = getInitialEditablePicks();
    setIsEditing(true);
    setIsFormEditable(true);
    setOriginalPicks({ ...selectedPicks });
    setOriginalTiebreaker(tiebreaker);
    setEditablePicks(newEditablePicks);
    clearSessionStorage();
    resetViewport();
  }, [selectedPicks, tiebreaker, getInitialEditablePicks]);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
    setIsFormEditable(false);
    setSelectedPicks(originalPicks);
    setTiebreaker(originalTiebreaker);
    resetViewport();
  }, [originalPicks, originalTiebreaker]);

  useEffect(() => {
    resetViewport();
  }, []);

  const [timeLeft, setTimeLeft] = useState(0);
  const [progress, setProgress] = useState(0);
  const weekInSubmissionPeriod = isWeekInSubmissionPeriod(currentWeek);
  const weekIsViewable = isWeekViewable(currentWeek);

  const nextWeekData = useMemo(() => {
    if (!weekInSubmissionPeriod && weekIsViewable) {
      const nextWeek = currentWeek + 1;
      const targetDate = new Date(weekDates[nextWeek].availableFrom);
      const startDate = new Date(weekDates[currentWeek].viewableFrom);
      return { nextWeek, targetDate, startDate };
    }
    return null;
  }, [weekInSubmissionPeriod, weekIsViewable]);

  useEffect(() => {
    if (nextWeekData) {
      const { targetDate, startDate } = nextWeekData;
      const updateTimeAndProgress = () => {
        const now = new Date();
        const difference = targetDate - now;
        const totalSeconds = Math.floor(difference / 1000);
        setTimeLeft(totalSeconds);
        const totalDuration = targetDate - startDate;
        const elapsed = now - startDate;
        setProgress((elapsed / totalDuration) * 100);

        // When countdown reaches zero, clear cache and trigger data refresh
        if (totalSeconds <= 0) {
          clearSessionStorage();
          fetchUserData();
          window.location.reload(); // Force a full refresh to get new week data
        }
      };
      updateTimeAndProgress();
      const interval = setInterval(updateTimeAndProgress, 1000);
      return () => clearInterval(interval);
    }
  }, [nextWeekData, fetchUserData]);

  const formatTime = (seconds) => {
    if (seconds <= 0) return "0d 0h 0m 0s";
    
    // If less than 2 minutes (120 seconds), show seconds
    if (seconds <= 120) {
      const mins = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${mins}m ${secs}s`;
    }
    
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${days}d ${hours}h ${minutes}m`;
  };

  useEffect(() => {
    setHideBottomNav(isEditing);
    if (!isEditing) {
      resetViewport();
    }
  }, [isEditing, setHideBottomNav]);

  useEffect(() => {
    const checkAllPicksSubmitted = () => {
      if (selectedPicks && Object.keys(selectedPicks).length > 0) {
        const upcomingGames = teams.filter((game) => {
          const now = new Date();
          const gameStart = new Date(game.startTime);
          return gameStart > now;
        });

        if (upcomingGames.length === 0) {
          setHasSubmittedAllPicks(true);
          return;
        }

        const hasSubmittedAllUpcoming = upcomingGames.every((game) => {
          const gameIndex = teams.indexOf(game);
          return (
            selectedPicks[gameIndex] &&
            selectedPicks[gameIndex] !== "Not Submitted"
          );
        });

        setHasSubmittedAllPicks(hasSubmittedAllUpcoming);
      } else {
        setHasSubmittedAllPicks(false);
      }
    };
    checkAllPicksSubmitted();
  }, [selectedPicks, teams, setHasSubmittedAllPicks]);

  const getNextDeadline = useCallback((games) => {
    const now = new Date();
    const upcomingGames = games.filter(
      (game) => new Date(game.startTime) > now
    );
    if (upcomingGames.length === 0) {
      return "All games started";
    }
    const nextGame = upcomingGames[0];
    return (
      new Date(nextGame.startTime).toLocaleString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
        timeZone: "America/New_York",
      }) + " ET"
    );
  }, []);

  const getPickStatus = useCallback((gameIndex, pick) => {
    const winners = getWinnersForWeek(currentWeek);
    const gameResult = winners[gameIndex];
    if (!gameResult || gameResult.winner === "NULL") {
      return "pending";
    }
    if (gameResult.winner === "PUSH") {
      return "push";
    }
    return isPickCorrect(pick, gameResult) ? "correct" : "incorrect";
  }, []);

  const sortGameCards = useCallback(
    (games) => {
      return [...games].sort((a, b) => {
        const statusA = getDisplayStatus(a);
        const statusB = getDisplayStatus(b);

        const getPriority = (status) => {
          if (!status) return 1;
          if (status === "FINAL") return 2;
          return 3; // LIVE
        };

        const priorityA = getPriority(statusA);
        const priorityB = getPriority(statusB);

        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        }

        if (statusA === "FINAL" && statusB === "FINAL") {
          return new Date(b.startTime) - new Date(a.startTime);
        }

        return new Date(a.startTime) - new Date(b.startTime);
      });
    },
    [getDisplayStatus]
  );

  useEffect(() => {
    const storedWeek = localStorage.getItem("lastKnownWeek");
    const currentWeekNumber = currentWeek;
    if (storedWeek && parseInt(storedWeek, 10) !== currentWeekNumber) {
      clearSessionStorage();
    }
    localStorage.setItem("lastKnownWeek", currentWeekNumber);
  }, []);

  if (isLoading || getUserWeekPicks === undefined) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <Loader2 className="h-16 w-16 animate-spin text-blue-500" />
      </div>
    );
  }

  if (!weekInSubmissionPeriod && weekIsViewable && nextWeekData) {
    return (
      <div className="flex justify-center items-center min-h-[80vh] bg-gray-900 p-4">
        <div className="bg-gray-800/80 p-10 rounded-3xl shadow-xl max-w-md w-full mx-auto border border-gray-700/50">
          {/* Week number */}
          <h1 className="text-4xl font-bold text-white text-center mb-12">
            Week {nextWeekData.nextWeek}
          </h1>

          {/* Progress and countdown */}
          <div className="space-y-8 mb-12">
            {/* Animated progress bar */}
            <div className="relative">
              <div className="absolute inset-0 bg-blue-400/20 rounded-full blur-md"></div>
              <div className="relative h-2 bg-gray-700 rounded-full overflow-hidden">
                <div
                  className="absolute top-0 left-0 h-full w-full bg-gradient-to-r from-blue-500 to-blue-400 rounded-full transition-all duration-500 ease-out"
                  style={{ 
                    transform: `translateX(-${100 - Math.min(progress, 100)}%)`,
                  }}
                />
              </div>
            </div>

            {/* Countdown */}
            <div className="text-center">
              <div className="text-4xl font-bold text-blue-400 tabular-nums tracking-tight">
                {formatTime(timeLeft)}
              </div>
            </div>
          </div>

          {/* Status and date */}
          <div className="space-y-6 text-center">
            <p className="text-lg text-gray-300">
              Preparing matchups for next week
            </p>

            <div className="text-sm text-gray-400">
              <div className="mb-1">Available on</div>
              <div className="text-gray-200 font-medium">
                {nextWeekData.targetDate.toLocaleString("en-US", {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true,
                  timeZone: 'America/New_York'
                })} EST
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (hasSubmittedPicks && !isEditing) {
    return (
      <div className="flex flex-col items-center justify-start pt-12">
        <div className="bg-gradient-to-b from-gray-800/80 to-gray-800/40 rounded-2xl p-8 max-w-md w-full text-center backdrop-blur-sm border border-gray-700/50 shadow-xl">
          <div className="flex flex-col items-center">
            <div className="relative mb-6">
              <div className="absolute inset-0 bg-green-500/20 rounded-full blur-xl animate-pulse"></div>
              <div className="relative bg-gradient-to-b from-green-500 to-green-600 rounded-full p-3">
                <CheckCircle className="w-10 h-10 text-gray-900" />
              </div>
            </div>
            <h3 className="text-3xl font-bold mb-3 bg-gradient-to-r from-gray-100 to-gray-300 text-transparent bg-clip-text">
              Picks Submitted
            </h3>
            <p className="text-gray-400 mb-8 leading-relaxed max-w-sm">
              Your picks are locked in. Click below to view or modify your
              selections.
            </p>
            <Button
              onClick={handleEdit}
              color="navy"
              variant="outline"
              className="group relative flex items-center gap-2 px-8 py-2.5 bg-gray-700/50 hover:bg-gray-700 border-gray-600 transition-all duration-300"
            >
              <Edit className="w-4 h-4 transition-transform group-hover:rotate-12" />
              <span className="font-medium">View & Edit Picks</span>
              <div className="absolute inset-0 rounded-md bg-blue-400/10 group-hover:bg-blue-400/20 transition-colors duration-300"></div>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`space-y-6 ${hasSubmittedPicks && !isEditing ? "overflow-hidden" : ""}`}
    >
      <div className="relative overflow-hidden bg-gradient-to-br from-blue-600/10 via-gray-800 to-gray-900 rounded-xl shadow-xl p-8 border border-gray-700/50">
        <div className="absolute top-0 right-0 w-48 h-48 bg-blue-500/10 rounded-full blur-3xl -z-10"></div>
        <div className="absolute bottom-0 left-0 w-32 h-32 bg-blue-600/10 rounded-full blur-2xl -z-10"></div>
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          <div className="flex items-center space-x-5">
            <div className="bg-gradient-to-br from-blue-500/20 to-blue-600/20 rounded-xl p-3.5 ring-1 ring-blue-500/20">
              <svg
                className="w-7 h-7 text-blue-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
              </svg>
            </div>
            <div>
              <div className="flex items-center gap-3 pb-2">
                <h2 className="text-2xl font-bold text-white">
                  Week {currentWeek}
                </h2>
                <div className="flex items-center mt-1">
                  <span
                    className={`inline-block w-2 h-2 rounded-full mr-2 ${
                      hasSubmittedPicks
                        ? isEditing
                          ? "bg-yellow-400"
                          : Object.keys(selectedPicks).length === teams.length
                            ? "bg-green-400"
                            : "bg-yellow-400"
                        : "bg-blue-400"
                    }`}
                  ></span>
                  <p className="text-sm font-medium text-gray-400">
                    {hasSubmittedPicks
                      ? isEditing
                        ? "Editing"
                        : Object.keys(selectedPicks).length === teams.length
                          ? "Submitted"
                          : "Not Complete"
                      : "Picks needed"}
                  </p>
                </div>
              </div>
              <div className="text-sm space-y-1">
                <p className="font-medium text-gray-400">Next Deadline:</p>
                <p className="text-gray-300">
                  {teams.length > 0 ? getNextDeadline(teams) : "TBD"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && isFirstFetch ? (
        <div className="flex justify-center items-center h-[60vh]">
          <Loader2 className="h-16 w-16 animate-spin text-blue-500" />
        </div>
      ) : !hasSubmittedPicks || isEditing ? (
        <form onSubmit={(e) => e.preventDefault()} className="space-y-6 pb-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {sortGameCards(teams).map((game, idx) => (
              <GameCard
                key={idx}
                game={game}
                index={teams.indexOf(game)}
                handlePickClick={handlePickClick}
                selectedPicks={selectedPicks}
                editablePicks={editablePicks}
                getDisplayStatus={getDisplayStatus}
                getPickStatus={getPickStatus}
              />
            ))}
          </div>

          <Card className="bg-gray-800 border-gray-700">
            <CardHeader className="pb-3">
              <CardTitle className="text-lg font-semibold text-gray-100">
                Monday Night Tiebreaker
              </CardTitle>
              <p className="text-sm text-gray-400">
                Predict the total combined score for Monday's game:
                <span className="block mt-1 text-xs text-gray-500">
                  {teams[teams.length - 1]?.away.name} @{" "}
                  {teams[teams.length - 1]?.home.name}
                </span>
              </p>
            </CardHeader>
            <CardContent>
              <TiebreakerInput
                tiebreakerValue={tiebreaker}
                onChange={setTiebreaker}
                isFormEditable={isFormEditable}
                isEditing={isEditing}
              />
            </CardContent>
          </Card>

          {!hasSubmittedPicks && (
            <div className="flex justify-center mt-8">
              <Button
                disabled={isSubmitting}
                className="w-full max-w-xs"
                color="blue"
                onClick={handleSubmit}
                type="button"
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Submitting...
                  </>
                ) : (
                  "Submit Picks"
                )}
              </Button>
            </div>
          )}
        </form>
      ) : null}

      <div
        className={`fixed bottom-0 left-0 right-0 bg-gray-900/95 border-t border-gray-800 backdrop-blur-sm p-4 pb-[calc(1rem+env(safe-area-inset-bottom))] z-50 ${
          isEditing ? "block" : "hidden"
        }`}
      >
        <div className="max-w-7xl mx-auto flex justify-center gap-4">
          <Button
            variant="base"
            color="danger"
            onClick={handleCancelEdit}
            disabled={isSubmitting}
            className="w-32"
            type="button"
          >
            Cancel
          </Button>
          <Button
            color="success"
            disabled={isSubmitting}
            className="w-32"
            onClick={handleSubmit}
            type="button"
          >
            {isSubmitting ? (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              "Update"
            )}
          </Button>
        </div>
      </div>

      {isUploading && <PicksLoader onComplete={() => setIsUploading(false)} />}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalContent.title}
        message={modalContent.message}
        type={modalContent.type}
      />
    </div>
  );
}

export default HomePage;
