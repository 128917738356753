import React, { useMemo, useState } from "react";
import { Dialog, DialogTitle, DialogBody, DialogActions } from "./dialog";
import { Mail, User, ChevronDown } from "lucide-react";

const userEmailMap = {
  weedenmh: "weedenmh@gmail.com",
  Weeds11: "robinweeden64@gmail.com",
  Bleet: "ben_leet@icloud.com",
  JeremyBinding: "jeremy.binding20@gmail.com",
  bthomas1090: "bthomas1090@gmail.com",
  anthonyq15: "anthonyquintiliani15@gmail.com",
  dharitos72: "dharitos72@gmail.com",
  daschdegger: "daschdegger@gmail.com",
  remmij: "jamesgagne0@gmail.com",
  pblang: "blangpab@gmail.com",
  Jzebzda: "jackzebzda@gmail.com",
  marvizu: "melesio.arvizu@yahoo.com",
  iengsj: "sydneyieng@yahoo.com",
  TOB: "travisobrien96@gmail.com",
  camgodinsky: "cameron.godinsky@gmail.com",
  lstokes10: "ljstokes10@gmail.com",
  ttardiff20: "ttardiff824@gmail.com",
};

const InboxModal = ({
  isOpen,
  onClose,
  currentWeek,
  usersWithoutPicks,
  unselectedGames = {},
}) => {
  const [expandedUsers, setExpandedUsers] = useState(new Set());

  const isGameToday = (gameStartTime) => {
    const today = new Date();
    const gameDate = new Date(gameStartTime);
    return (
      today.getFullYear() === gameDate.getFullYear() &&
      today.getMonth() === gameDate.getMonth() &&
      today.getDate() === gameDate.getDate()
    );
  };

  const hasGameToday = (username) => {
    if (!unselectedGames[username]) return false;
    return unselectedGames[username].some((game) =>
      isGameToday(game.startTime)
    );
  };

  const toggleUserExpanded = (username) => {
    setExpandedUsers((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(username)) {
        newSet.delete(username);
      } else {
        newSet.add(username);
      }
      return newSet;
    });
  };

  const emailsWithoutPicks = useMemo(
    () =>
      usersWithoutPicks
        .map((username) => userEmailMap[username])
        .filter(Boolean),
    [usersWithoutPicks]
  );

  const createMailtoLink = (recipients, username = "", useBcc = false) => {
    const subject = encodeURIComponent(
      `Reminder: Submit Your Picks for Week ${currentWeek}`
    );

    let missingGames = "";
    if (
      username &&
      unselectedGames[username] &&
      unselectedGames[username].length > 0
    ) {
      missingGames =
        "\n\nGames still needing picks:\n" +
        unselectedGames[username]
          .map((game) => `- ${game.away.name} @ ${game.home.name}`)
          .join("\n");
    }

    const body = encodeURIComponent(
      `Hello,\n\nThis is a friendly reminder to submit your picks for Week ${currentWeek}.${missingGames}\n\nThank you!`
    );
    
    if (useBcc) {
      return `mailto:info@pick-em-pool.com?subject=${subject}&body=${body}&bcc=${recipients.join(",")}`;
    }

    return `mailto:${recipients[0]}?subject=${subject}&body=${body}`;
  };

  const handleRemindAll = () => {
    window.location.href = createMailtoLink(emailsWithoutPicks, "", true);
  };

  const handleRemindUser = (username) => {
    const email = userEmailMap[username];
    if (email) {
      window.location.href = createMailtoLink([email], username);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} size="sm">
      <div className="flex flex-col h-[60vh] sm:h-[450px] w-full max-w-md">
        <DialogTitle className="flex-shrink-0 px-4 py-3 border-b border-gray-700">
          <div className="flex justify-between items-center">
            <h2 className="text-base sm:text-lg font-semibold text-gray-100">
              No Picks:
            </h2>
            <button
              onClick={handleRemindAll}
              className="bg-indigo-600 text-white px-3 py-1.5 text-xs font-medium rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center space-x-1"
            >
              <Mail className="w-3 h-3" />
              <span>Remind All</span>
            </button>
          </div>
        </DialogTitle>
        <DialogBody className="flex-grow overflow-y-auto px-3 py-2 sm:px-4 sm:py-3">
          {usersWithoutPicks.length > 0 ? (
            <ul className="space-y-2">
              {usersWithoutPicks.map((username) => (
                <li key={username} className="rounded-lg overflow-hidden">
                  <div className="flex flex-col">
                    <div
                      onClick={(e) => {
                        if (!e.target.closest(".remind-button")) {
                          toggleUserExpanded(username);
                        }
                      }}
                      className={`flex items-center justify-between px-4 py-3 transition-colors cursor-pointer ${
                        hasGameToday(username)
                          ? "bg-yellow-500/20 hover:bg-yellow-500/30"
                          : "bg-gray-700 hover:bg-gray-600"
                      }`}
                    >
                      <div className="flex items-center space-x-3 flex-1">
                        <User className="text-gray-400 w-4 h-4" />
                        <span className="text-sm font-medium text-gray-200">
                          {username}
                        </span>
                        <ChevronDown
                          className={`w-4 h-4 text-gray-400 transition-transform duration-200 ${
                            expandedUsers.has(username) ? "rotate-180" : ""
                          }`}
                        />
                      </div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemindUser(username);
                        }}
                        className="remind-button inline-flex items-center bg-indigo-600 text-white px-3 py-1.5 text-xs font-medium rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 space-x-1"
                      >
                        <Mail className="w-3 h-3" />
                        <span>Remind</span>
                      </button>
                    </div>
                    {unselectedGames[username] &&
                      unselectedGames[username].length > 0 &&
                      expandedUsers.has(username) && (
                        <div className="bg-gray-800 border-t border-gray-600">
                          <div className="px-4 py-3">
                            <p className="text-xs font-medium text-gray-300 mb-2">
                              Missing picks:
                            </p>
                            <div className="grid gap-2">
                              {unselectedGames[username].map((game, idx) => (
                                <div
                                  key={idx}
                                  className={`flex items-center justify-between px-3 py-2 rounded-md ${
                                    isGameToday(game.startTime)
                                      ? "bg-yellow-500/20"
                                      : "bg-gray-700/50"
                                  }`}
                                >
                                  <div className="flex items-center space-x-2">
                                    <span className="text-xs text-gray-400">
                                      {game.away.name}
                                    </span>
                                    <span className="text-xs text-gray-500">
                                      @
                                    </span>
                                    <span className="text-xs text-gray-400">
                                      {game.home.name}
                                    </span>
                                  </div>
                                  {isGameToday(game.startTime) && (
                                    <span className="text-xs text-yellow-500 font-medium">
                                      Today
                                    </span>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-xs text-gray-300">
              All users have submitted their picks for this week.
            </p>
          )}
        </DialogBody>
        <DialogActions className="flex-shrink-0 px-3 py-2 sm:px-4 sm:py-3 border-t border-gray-700">
          <button
            onClick={onClose}
            className="w-full bg-gray-700 text-gray-200 px-3 py-2 text-sm font-medium rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Close
          </button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default InboxModal;
