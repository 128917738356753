import React from "react";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Dialog, DialogContent, DialogTitle, DialogFooter } from "./ui/dialog";
import { Button } from "./button"; // Assuming you have a Button component
import { useDialog } from "../context/DialogContext";

const AddToHome = ({ isOpen, onClose }) => {
  const { setIsDialogOpen } = useDialog();

  React.useEffect(() => {
    setIsDialogOpen(isOpen);
  }, [isOpen, setIsDialogOpen]);

  // Add this useEffect to preload the image
  React.useEffect(() => {
    const img = new Image();
    img.src =
      "https://utfs.io/f/7P1pSdZBrdR0FgO9FWUNWSIUAYLoKjlQ5nqeu9ygEHwsMhar";
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent className="bg-gray-900 border-gray-800 w-[90%] sm:max-w-[360px] p-0 gap-0 mx-auto rounded-lg">
        <DialogTitle className="text-xl font-bold text-white text-center p-3 sm:p-4 border-b border-gray-800">
          Install Our App
        </DialogTitle>

        <div className="p-3 sm:p-4">
          <div className="flex flex-col items-center space-y-3 sm:space-y-4">
            {/* App icon container - enhanced shadow and glow effect */}
            <div className="bg-gray-800 p-2 sm:p-3 rounded-2xl shadow-lg shadow-primary/20 ring-1 ring-gray-700">
              <img
                src="https://utfs.io/f/7P1pSdZBrdR0FgO9FWUNWSIUAYLoKjlQ5nqeu9ygEHwsMhar"
                alt="App Icon"
                className="w-12 h-12 sm:w-16 sm:h-16 rounded-xl"
              />
            </div>

            <p className="text-center text-gray-300 text-sm font-medium">
              Get instant access from your home screen
            </p>

            {/* Instructions container - improved spacing and visual hierarchy */}
            <div className="space-y-6 w-full bg-gray-800/50 rounded-xl p-4 ring-1 ring-gray-700">
              <div className="flex flex-col items-center space-y-3">
                <span className="bg-primary/90 text-white text-xs font-semibold px-3 py-1 rounded-full">
                  Step 1
                </span>
                <p className="text-gray-300 text-sm font-medium">
                  Tap the share icon
                </p>
                <IosShareIcon
                  className="text-blue-500"
                  style={{ fontSize: "2em" }}
                />
              </div>

              <div className="border-t border-gray-700/50 w-2/3 mx-auto"></div>

              <div className="flex flex-col items-center space-y-3">
                <span className="bg-primary/90 text-white text-xs font-semibold px-3 py-1 rounded-full">
                  Step 2
                </span>
                <p className="text-gray-300 text-sm">
                  Select{" "}
                  <span className="font-semibold text-white">
                    Add to Home Screen
                  </span>
                </p>
              </div>
            </div>

            <p className="text-center text-xs text-gray-400">
              Access native app-like features. No download required.
            </p>
          </div>
        </div>

        <DialogFooter className="p-3 sm:p-4 border-t border-gray-800 bg-gray-800/50">
          <Button
            onClick={onClose}
            color="blue"
            className="w-full hover:bg-primary/90 text-white font-semibold py-2.5 px-4 rounded-lg text-sm transition-all duration-200 hover:shadow-lg hover:shadow-primary/20"
          >
            Maybe Later
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddToHome;
