import React, { useState } from "react";
import { SignIn } from "@clerk/clerk-react";
import SignupModal from "./SignupModal";

const LoginComponent = () => {
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const SIGNUP_CUTOFF_DATE = new Date("2024-09-01");
  const isSignupDisabled = new Date() > SIGNUP_CUTOFF_DATE;

  return (
    <main className="h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex flex-col items-center justify-center py-8">
      <div className="w-full max-w-md px-4 space-y-4">
        <SignIn
          fallbackRedirectUrl="/leaderboard"
          forceRedirectUrl="/leaderboard"
          redirectUrl="/leaderboard"
          appearance={{
            elements: {
              rootBox: "w-full",
              card: "rounded-xl bg-gray-800 shadow-2xl ring-1 ring-white/20",
              headerSubtitle: "text-gray-400",
              headerTitle: "text-white text-2xl mt-4",
              header: "flex flex-col items-center",
              socialButtonsBlockButton:
                "bg-gray-700/50 hover:bg-gray-700/75 transition-colors duration-200 ring-1 ring-white/10",
              socialButtonsBlockButtonText: "text-white font-medium",
              dividerLine: "bg-gray-700",
              dividerText: "text-gray-400",
              formFieldLabel: "text-gray-300",
              formFieldInput:
                "bg-white border-gray-700 text-black placeholder-gray-500 focus:border-blue-500 focus:ring-blue-500/50 rounded-lg",
              formFieldInputControl: {
                autoCapitalize: "none",
              },
              formButtonPrimary:
                "bg-blue-600 hover:bg-blue-500 text-white transition-colors duration-200 rounded-lg",
              footerActionLink: "text-blue-400 hover:text-blue-300",
              formFieldInputShowPasswordButton:
                "text-gray-400 hover:text-gray-300",
              identityPreviewText: "text-gray-300",
              identityPreviewEditButton: "text-blue-400 hover:text-blue-300",
              footer: "hidden",
              footerAction: "hidden",
              logoBox: "pointer-events-none",
              logoImage: "rounded-xl w-16 h-16",
            },
            layout: {
              socialButtonsPlacement: "top",
              socialButtonsVariant: "blockButton",
              privacyPageUrl: "https://clerk.dev/privacy",
              termsPageUrl: "https://clerk.dev/terms",
              showOptionalFields: !isSignupDisabled,
              logoImageUrl:
                "https://utfs.io/f/7P1pSdZBrdR0FgO9FWUNWSIUAYLoKjlQ5nqeu9ygEHwsMhar",
              logoPlacement: "inside",
            },
            variables: {
              colorPrimary: "#3b82f6", // blue-500
              colorTextOnPrimaryBackground: "#ffffff",
              colorBackground: "#1f2937", // Solid gray-800 instead of transparent
              colorInputBackground: "#ffffff",
              colorInputText: "#000000",
              colorTextSecondary: "#9ca3af", // gray-400
              borderRadius: "0.75rem",
            },
          }}
        />

        {/* Custom Sign Up Section */}
        {!isSignupDisabled && (
          <div className="text-center mt-1">
            <p className="text-gray-400 text-sm">
              Don't have an account?{" "}
              <button
                onClick={() => setIsSignupModalOpen(true)}
                className="text-blue-400 hover:text-blue-300 font-medium transition-colors ml-1"
              >
                Sign up
              </button>
            </p>
          </div>
        )}
      </div>

      <SignupModal
        isOpen={isSignupModalOpen}
        onClose={() => setIsSignupModalOpen(false)}
      />
    </main>
  );
};

export default LoginComponent;
