import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Bell,
  User,
  ChevronDown,
  ChartBarIcon,
  Smartphone,
  AlertCircle,
  LogOut,
} from "lucide-react";
import AddToHome from "./AddToHome";
import { getCurrentWeek } from "./weekDates";
import InboxModal from "./InboxModal";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { useClerk } from "@clerk/clerk-react";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { getGamesForWeek, hasGameStarted } from "../gameData";
import { getCurrentHolidays } from "../utils/holidays";

const USERNAMES = [
  "weedenmh",
  "weeds11",
  "bleet",
  "jeremybinding",
  "bthomas1090",
  "anthonyq15",
  "dharitos72",
  "daschdegger",
  "remmij",
  "pblang",
  "jzebzda",
  "marvizu",
  "iengsj",
  "tobb",
  "camgodinsky",
  "lstokes10",
  "ttardiff20",
];

const Header = ({ userName, showPicksWarning: initialShowPicksWarning }) => {
  const [isAddToHomeOpen, setIsAddToHomeOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);
  const [isInboxOpen, setIsInboxOpen] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);
  const [usersWithoutPicks, setUsersWithoutPicks] = useState([]);
  const navigate = useNavigate();
  const currentWeek = getCurrentWeek();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const profileRef = useRef();
  const { signOut, openUserProfile } = useClerk();
  const [showPicksWarningState, setShowPicksWarningState] = useState(
    initialShowPicksWarning
  );
  const [unselectedGames, setUnselectedGames] = useState({});
  const currentHolidays = getCurrentHolidays();

  const weekString = currentWeek.toString();
  const weekPicks = useQuery(api.picks.getWeekPicks, { week: weekString });

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent.toLowerCase()
        )
      );
    };

    const checkIfStandalone = () => {
      setIsStandalone(window.matchMedia("(display-mode: standalone)").matches);
    };

    checkIfMobile();
    checkIfStandalone();
    window.addEventListener("resize", checkIfMobile);
    window.addEventListener("resize", checkIfStandalone);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
      window.removeEventListener("resize", checkIfStandalone);
    };
  }, []);

  useEffect(() => {
    if (userName === "pblang" && weekPicks) {
      const games = getGamesForWeek(currentWeek);
      const now = new Date();
      const usersWithMissingPicks = [];
      const unsubmittedGames = {};

      // Check each user's picks
      USERNAMES.forEach((username) => {
        const userPick = weekPicks.find(
          (pick) => pick.username.toLowerCase() === username.toLowerCase()
        );

        let hasMissingPicks = false;
        const missingGames = [];

        // Get upcoming games
        const upcomingGames = games.filter((game) => {
          const gameStart = new Date(game.startTime);
          return gameStart > now;
        });

        if (!userPick) {
          // User has no picks at all
          hasMissingPicks = upcomingGames.length > 0;
          missingGames.push(...upcomingGames);
        } else {
          // User has some picks, check each upcoming game
          try {
            const picks =
              typeof userPick.picks === "string"
                ? JSON.parse(userPick.picks)
                : userPick.picks;

            upcomingGames.forEach((game, idx) => {
              const gameIndex = games.indexOf(game);
              if (!picks[gameIndex] || picks[gameIndex] === "Not Submitted") {
                hasMissingPicks = true;
                missingGames.push(game);
              }
            });
          } catch (error) {
            console.error("Error parsing picks for user:", username, error);
            hasMissingPicks = upcomingGames.length > 0;
            missingGames.push(...upcomingGames);
          }
        }

        if (hasMissingPicks) {
          usersWithMissingPicks.push(username);
          unsubmittedGames[username] = missingGames;
        }
      });

      setUsersWithoutPicks(usersWithMissingPicks);
      setUnselectedGames(unsubmittedGames);
      setHasNotification(usersWithMissingPicks.length > 0);
    }
  }, [userName, weekPicks, currentWeek]);

  useEffect(() => {
    if (isMobile && !isStandalone) {
      const img = new Image();
      img.src =
        "https://utfs.io/f/7P1pSdZBrdR0FgO9FWUNWSIUAYLoKjlQ5nqeu9ygEHwsMhar";
    }
  }, [isMobile, isStandalone]);

  useOnClickOutside(profileRef, () => setIsProfileOpen(false));

  const closeAddToHome = () => {
    setIsAddToHomeOpen(false);
  };

  const openInbox = () => {
    setIsInboxOpen(true);
  };

  const closeInbox = () => {
    setIsInboxOpen(false);
  };

  useEffect(() => {
    if (weekPicks) {
      const userPicks = weekPicks.find(
        (pick) => pick.username.toLowerCase() === userName.toLowerCase()
      );

      if (!userPicks) {
        setShowPicksWarningState(true);
        return;
      }

      try {
        const picks =
          typeof userPicks.picks === "string"
            ? JSON.parse(userPicks.picks)
            : userPicks.picks;

        const games = getGamesForWeek(currentWeek);

        // Check if all upcoming games have valid picks
        const now = new Date();
        const upcomingGames = games.filter((game) => {
          const gameStart = new Date(game.startTime);
          return gameStart > now;
        });

        // If no upcoming games, don't show warning
        if (upcomingGames.length === 0) {
          setShowPicksWarningState(false);
          return;
        }

        // Check if all upcoming games have valid picks
        const hasSubmittedAllUpcoming = upcomingGames.every((game, index) => {
          const gameIndex = games.indexOf(game);
          return picks[gameIndex] && picks[gameIndex] !== "Not Submitted";
        });

        setShowPicksWarningState(!hasSubmittedAllUpcoming);
      } catch (error) {
        console.error("Error checking picks:", error);
        setShowPicksWarningState(true);
      }
    }
  }, [weekPicks, userName, currentWeek]);

  // Filter holidays that have images
  const holidaysWithImages = currentHolidays.filter(
    (holiday) => holiday.imageUrl
  );

  return (
    <>
      <header
        className="fixed top-0 left-0 right-0 bg-gray-900/95 backdrop-blur-lg text-white border-b border-gray-800/50 z-50"
        style={{
          paddingTop: "env(safe-area-inset-top)",
          height: "calc(70px + env(safe-area-inset-top))",
        }}
      >
        <div className="container mx-auto px-4 py-2 h-full">
          <div className="flex items-center justify-between h-full">
            {/* Left section */}
            <div className="w-32">
              <h1 className="text-2xl font-bold font-heading flex items-center">
                <img
                  src="https://utfs.io/f/7P1pSdZBrdR0FgO9FWUNWSIUAYLoKjlQ5nqeu9ygEHwsMhar"
                  alt="Logo"
                  className="h-12 w-12 rounded-full cursor-pointer"
                  onClick={() => navigate("/")}
                />
              </h1>
            </div>

            {/* Right section */}
            <div className="w-32 flex justify-end">
              <div className="flex items-center space-x-5">
                {userName === "pblang" && (
                  <button
                    onClick={openInbox}
                    className="p-2 rounded-full relative"
                    aria-label="Open notifications"
                    aria-haspopup="true"
                    aria-expanded={isInboxOpen}
                  >
                    <Bell className="h-5 w-5 text-gray-300" />
                    {hasNotification && (
                      <span
                        className="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full bg-red-500 ring-2 ring-gray-900 animate-pulse"
                        aria-label="New notifications available"
                      />
                    )}
                  </button>
                )}
                <div className="relative" ref={profileRef}>
                  <button
                    onClick={() => setIsProfileOpen(!isProfileOpen)}
                    className="flex items-center justify-center space-x-2 bg-gray-800/40 hover:bg-gray-800/60 text-sm rounded-full px-4 py-2 focus:outline-none focus-visible:ring-0 border border-gray-700/20 hover:border-gray-700/40 backdrop-blur-sm"
                  >
                    <div
                      className={`h-7 w-7 rounded-full flex items-center justify-center ${
                        showPicksWarningState
                          ? "bg-yellow-500/30 animate-pulse"
                          : "bg-gray-700"
                      }`}
                    >
                      <User className="h-4 w-4 text-gray-300" />
                    </div>
                    <ChevronDown
                      className={`h-4 w-4 text-gray-400 transition-transform duration-200 ${
                        isProfileOpen ? "rotate-180" : ""
                      }`}
                    />
                  </button>

                  {isProfileOpen && (
                    <div className="absolute right-0 mt-2 w-72 rounded-2xl shadow-lg bg-gray-800/95 backdrop-blur-xl ring-1 ring-gray-700/50 border border-gray-700/50 transform opacity-100 scale-100 transition-all duration-200 ease-out">
                      <div
                        className="p-3 space-y-1"
                        role="menu"
                        aria-orientation="vertical"
                      >
                        {/* Account Management Section */}
                        <button
                          onClick={() => {
                            openUserProfile();
                            setIsProfileOpen(false);
                          }}
                          className="w-full text-left px-3 py-2.5 text-sm text-gray-200 rounded-xl hover:bg-gray-700/50 transition-all duration-150 group"
                          role="menuitem"
                        >
                          <div className="flex items-center">
                            <div className="h-10 w-10 rounded-xl flex items-center justify-center mr-3">
                              <User className="h-5 w-5 text-gray-300" />
                            </div>
                            <div>
                              <div className="font-medium">Manage Account</div>
                              <div className="text-xs text-gray-400">
                                Update your profile and preferences
                              </div>
                            </div>
                          </div>
                        </button>

                        <div className="h-px bg-gray-700/50 my-2 mx-3"></div>

                        {/* Stats Section */}
                        <button
                          onClick={() => {
                            navigate("/profile");
                            setIsProfileOpen(false);
                          }}
                          className="w-full text-left px-3 py-2.5 text-sm text-gray-200 rounded-xl hover:bg-gray-700/50 transition-all duration-150 group"
                          role="menuitem"
                        >
                          <div className="flex items-center">
                            <div className="h-10 w-10 rounded-xl flex items-center justify-center mr-3">
                              <ChartBarIcon className="h-5 w-5 text-gray-300" />
                            </div>
                            <div>
                              <div className="font-medium tracking-tight">
                                Stats
                              </div>
                              <div className="text-xs text-gray-400 font-normal">
                                View your performance
                              </div>
                            </div>
                          </div>
                        </button>

                        {/* Add to Home Section - Only for mobile */}
                        {isMobile && !isStandalone && (
                          <button
                            onClick={() => {
                              setIsAddToHomeOpen(true);
                              setIsProfileOpen(false);
                            }}
                            className="w-full text-left px-3 py-2.5 text-sm text-gray-200 rounded-xl hover:bg-gray-700/50 transition-all duration-150 group"
                            role="menuitem"
                          >
                            <div className="flex items-center">
                              <div className="h-10 w-10 rounded-xl flex items-center justify-center mr-3">
                                <Smartphone className="h-5 w-5 text-gray-300" />
                              </div>
                              <div>
                                <div className="font-medium tracking-tight">
                                  Add to Home
                                </div>
                                <div className="text-xs text-gray-400 font-normal">
                                  Install app on your device
                                </div>
                              </div>
                            </div>
                          </button>
                        )}

                        {/* Picks Warning Section */}
                        {showPicksWarningState && (
                          <button
                            onClick={() => {
                              navigate("/home");
                              setIsProfileOpen(false);
                            }}
                            className="w-full text-left px-3 py-2.5 text-sm text-gray-200 rounded-xl bg-yellow-500/10 transition-all duration-150 group"
                            role="menuitem"
                          >
                            <div className="flex items-center">
                              <div className="h-10 w-10 rounded-xl bg-yellow-500/10 flex items-center justify-center mr-3 group-hover:bg-yellow-500/20">
                                <AlertCircle className="h-5 w-5 text-yellow-500" />
                              </div>
                              <div>
                                <div className="font-medium tracking-tight">
                                  Picks Not Submitted
                                </div>
                                <div className="text-xs text-gray-400 font-normal">
                                  Click to submit picks
                                </div>
                              </div>
                            </div>
                          </button>
                        )}

                        {/* Holiday Images Section */}
                        {holidaysWithImages.length > 0 && (
                          <>
                            <div className="h-px bg-gray-700/50 my-2 mx-3"></div>
                            {holidaysWithImages.map((holiday, index) => (
                              <button
                                key={holiday.name}
                                className="w-full text-left px-3 py-2.5 text-sm rounded-xl hover:bg-green-700/10 transition-all duration-150 group"
                                role="menuitem"
                              >
                                <div className="flex items-center justify-center">
                                  <img
                                    src={holiday.imageUrl}
                                    alt={holiday.imageAlt}
                                    className="h-32 w-64 object-contain rounded-lg"
                                  />
                                </div>
                              </button>
                            ))}
                          </>
                        )}

                        {/* Sign Out Section */}
                        <div className="h-px bg-gray-700/50 my-2 mx-3"></div>
                        <button
                          onClick={() => signOut()}
                          className="w-full text-left px-3 py-2.5 text-sm text-red-400 rounded-xl hover:bg-red-500/10 transition-all duration-150 group"
                          role="menuitem"
                        >
                          <div className="flex items-center">
                            <div className="h-10 w-10 rounded-xl flex items-center justify-center mr-3">
                              <LogOut className="h-5 w-5 text-red-400" />
                            </div>
                            <div>
                              <div className="font-medium tracking-tight">
                                Sign Out
                              </div>
                              <div className="text-xs text-red-400/70 font-normal">
                                End your session
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <AddToHome isOpen={isAddToHomeOpen} onClose={closeAddToHome} />
      {userName === "pblang" && (
        <InboxModal
          isOpen={isInboxOpen}
          onClose={closeInbox}
          currentWeek={currentWeek}
          usersWithoutPicks={usersWithoutPicks}
          unselectedGames={unselectedGames}
        />
      )}
    </>
  );
};

export default Header;
