import { formatInTimeZone } from "date-fns-tz";

// Set the time zone for all dates
const TIME_ZONE = "America/New_York";

const formatDate = (dateString) => {
  return formatInTimeZone(
    new Date(dateString),
    TIME_ZONE,
    "yyyy-MM-dd'T'HH:mm:ssXXX"
  );
};

const rawWeekDates = {
  1: {
    availableFrom: "2024-08-01T00:00:00-04:00",
    viewableFrom: "2024-09-09T20:20:00-04:00",
  },
  2: {
    availableFrom: "2024-09-11T13:00:00-04:00",
    viewableFrom: "2024-09-16T20:15:00-04:00",
  },
  3: {
    availableFrom: "2024-09-17T00:00:00-04:00",
    viewableFrom: "2024-09-23T20:15:00-04:00",
  },
  4: {
    availableFrom: "2024-09-24T00:00:00-04:00",
    viewableFrom: "2024-09-30T20:15:00-04:00",
  },
  5: {
    availableFrom: "2024-10-01T12:00:00-04:00",
    viewableFrom: "2024-10-07T20:15:00-04:00",
  },
  6: {
    availableFrom: "2024-10-09T12:00:00-04:00",
    viewableFrom: "2024-10-14T20:15:00-04:00",
  },
  7: {
    availableFrom: "2024-10-16T12:00:00-04:00",
    viewableFrom: "2024-10-21T20:00:00-04:00",
  },
  8: {
    availableFrom: "2024-10-23T12:00:00-04:00",
    viewableFrom: "2024-10-28T20:15:00-04:00",
  },
  9: {
    availableFrom: "2024-10-30T12:00:00-04:00",
    viewableFrom: "2024-11-04T20:15:00-04:00",
  },
  10: {
    availableFrom: "2024-11-06T12:00:00-05:00",
    viewableFrom: "2024-11-11T20:15:00-05:00",
  },
  11: {
    availableFrom: "2024-11-12T12:00:00-05:00",
    viewableFrom: "2024-11-18T20:15:00-05:00",
  },
  12: {
    availableFrom: "2024-11-19T00:00:00-05:00",
    viewableFrom: "2024-11-25T20:15:00-05:00",
  },
  13: {
    availableFrom: "2024-11-27T00:00:00-05:00",
    viewableFrom: "2024-12-02T20:15:00-05:00",
  },
  14: {
    availableFrom: "2024-12-04T12:00:00-05:00",
    viewableFrom: "2024-12-09T20:15:00-05:00",
  },
  15: {
    availableFrom: "2024-12-11T12:00:00-05:00",
    viewableFrom: "2024-12-16T20:15:00-05:00",
  },
  16: {
    availableFrom: "2024-12-18T12:00:00-05:00",
    viewableFrom: "2024-12-23T20:15:00-05:00",
  },
  17: {
    availableFrom: "2024-12-25T12:00:00-05:00",
    viewableFrom: "2024-12-30T20:15:00-05:00",
  },
  18: {
    availableFrom: "2025-01-01T12:00:00-05:00",
    viewableFrom: "2025-01-05T20:15:00-05:00",
  },
};

export const getWeekDates = (weekNumber) => {
  const dates = rawWeekDates[weekNumber];
  if (!dates) return null;

  return {
    availableFrom: formatDate(dates.availableFrom),
    viewableFrom: formatDate(dates.viewableFrom),
  };
};

export const isWeekAvailable = (weekNumber) => {
  const now = new Date();
  const weekDate = getWeekDates(weekNumber);

  return weekDate && now >= new Date(weekDate.availableFrom);
};

export const isWeekViewable = (weekNumber) => {
  const now = new Date();
  const weekDate = getWeekDates(weekNumber);

  return weekDate && now >= new Date(weekDate.viewableFrom);
};

export const getLatestAvailableWeek = () => {
  const now = new Date();
  let latestWeek = 1;

  for (let week = 1; week <= 18; week++) {
    const dates = getWeekDates(week);
    if (!dates) continue;

    if (now >= new Date(dates.availableFrom)) {
      latestWeek = week;
    } else {
      break;
    }
  }

  return latestWeek;
};

export function getCurrentWeek() {
  const now = new Date();
  for (let week = 1; week <= 18; week++) {
    const weekDate = getWeekDates(week);
    if (!weekDate) continue;

    const availableFrom = new Date(weekDate.availableFrom);
    const nextWeekDates = getWeekDates(week + 1);
    const nextWeekAvailableFrom = nextWeekDates
      ? new Date(nextWeekDates.availableFrom)
      : new Date(8640000000000000);

    if (now >= availableFrom && now < nextWeekAvailableFrom) {
      return week;
    }
  }
  return 1;
}

export const weekDates = rawWeekDates;

export default {
  getWeekDates,
  isWeekAvailable,
  isWeekViewable,
  getLatestAvailableWeek,
  getCurrentWeek,
  weekDates: rawWeekDates,
};
