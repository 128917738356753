// Holiday date configurations
const HOLIDAYS = {
  CHRISTMAS_SEASON: {
    month: 11, // December
    name: "Christmas Season",
    isDateRange: true,
    startDate: 1, // December 1st
    endDate: 31, // December 31st
  },
  CHRISTMAS: {
    month: 11, // 0-based month (December)
    date: 25,
    name: "Christmas",
    imageUrl:
      "https://utfs.io/f/7P1pSdZBrdR0JOQgZmJ1920GDsrwTXmSyfCcoElJ5LaW1evU",
    imageAlt: "Merry Christmas",
  },
  NEW_YEARS: {
    month: 0, // January
    date: 1,
    name: "New Year",
    imageUrl:
      "https://utfs.io/f/7P1pSdZBrdR0SyV6sOufP1cwl0K6yDmMupjt4Jb59eLvWgdT",
    imageAlt: "Happy New Year",
  },
  THANKSGIVING: {
    month: 10, // November
    // Thanksgiving is 4th Thursday of November
    getDate: (year) => {
      const firstDay = new Date(year, 10, 1);
      const dayOfWeek = firstDay.getDay();
      return 22 + ((11 - dayOfWeek) % 7);
    },
    name: "Thanksgiving",
    imageUrl: null,
    imageAlt: "Happy Thanksgiving",
  },
  // Add more holidays as needed
};

/**
 * Checks if today matches the specified holiday
 * @param {Object} holiday - Holiday object from HOLIDAYS
 * @returns {boolean}
 */
const isHolidayToday = (holiday) => {
  const today = new Date();
  const currentYear = today.getFullYear();

  // For holidays with dynamic dates (like Thanksgiving)
  if (holiday.getDate) {
    return (
      today.getMonth() === holiday.month &&
      today.getDate() === holiday.getDate(currentYear)
    );
  }

  // For date range holidays (like Christmas Season)
  if (holiday.isDateRange) {
    return (
      today.getMonth() === holiday.month &&
      today.getDate() >= holiday.startDate &&
      today.getDate() <= holiday.endDate
    );
  }

  // For holidays with fixed dates
  return today.getMonth() === holiday.month && today.getDate() === holiday.date;
};

/**
 * Gets all current holidays if today matches any holidays
 * @returns {Object[]} Array of holiday objects that are active today
 */
export const getCurrentHolidays = () => {
  return Object.values(HOLIDAYS).filter((holiday) => isHolidayToday(holiday));
};

/**
 * Gets the current holiday if today is a holiday (maintains backward compatibility)
 * @returns {Object|null} Holiday object or null if today is not a holiday
 */
export const getCurrentHoliday = () => {
  const holidays = getCurrentHolidays();
  return holidays.length > 0 ? holidays[0] : null;
};

export const HOLIDAY_CONFIGS = HOLIDAYS;
