import React from "react";
import { motion } from "motion/react";

const ChristmasLights = ({ className = "" }) => {
  const colors = [
    "rgb(239, 68, 68)", // red-500
    "rgb(34, 197, 94)", // green-500
    "rgb(234, 179, 8)", // yellow-500
    "rgb(59, 130, 246)", // blue-500
  ];

  const bulbVariants = {
    initial: {
      opacity: 1.0,
      filter: "brightness(1.0)",
    },
  };

  return (
    <div className={`relative w-full opacity-90 ${className}`}>
      {/* Wire - made slightly thinner */}
      <div className="absolute w-full h-[0.5px] bg-gradient-to-b from-gray-500 to-gray-600 top-0" />

      {/* Lights Container */}
      <div className="absolute w-full flex justify-center items-start">
        <div className="flex justify-between w-full max-w-[1200px] px-4">
          {[...Array(12)].map((_, index) => (
            <div key={index} className="relative">
              {/* Wire Connection - even thinner */}
              <div className="absolute w-[0.25px] h-[1.5px] bg-gray-500 left-1/2 -top-[1px] transform -translate-x-1/2" />

              {/* Bulb */}
              <motion.div
                initial="initial"
                animate="animate"
                variants={bulbVariants}
                style={{
                  transition: {
                    delay: index * 0.15, // Slightly faster sequence
                  },
                }}
                className="relative top-[2px]"
              >
                {/* Bulb Base - more minimal */}
                <div
                  className="w-[4px] h-[6px] rounded-t-full absolute -top-[2px] left-1/2 transform -translate-x-1/2"
                  style={{
                    background:
                      "linear-gradient(180deg, #f3f4f6 0%, #d1d5db 100%)",
                  }}
                />

                {/* Main Bulb - more minimal and slightly smaller */}
                <div
                  className="relative w-[10px] h-[14px] rounded-[6px]"
                  style={{
                    backgroundColor: colors[index % colors.length],
                    boxShadow: `
                      0 0 8px 2px ${colors[index % colors.length]}30,
                      inset 0 -1px 3px rgba(0,0,0,0.15),
                      inset 0 1px 2px rgba(255,255,255,0.4)
                    `,
                    transform: "translateY(4px)",
                  }}
                >
                  {/* Shine Effect - more subtle */}
                  <div
                    className="absolute w-[3px] h-[3px] rounded-full left-[2px] top-[2px]"
                    style={{
                      background:
                        "linear-gradient(135deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%)",
                    }}
                  />
                </div>
              </motion.div>
            </div>
          ))}
        </div>
      </div>

      {/* Bottom Wire Shadow - more subtle */}
      <div className="h-[0.25px] bg-gradient-to-b from-gray-500/50 to-transparent w-full" />
    </div>
  );
};

export default ChristmasLights;
