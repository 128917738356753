import React, { useState } from "react";
import { useSignUp } from "@clerk/clerk-react";
import { Eye, EyeOff, Mail, User, CreditCard } from "lucide-react";
import { useMutation } from "convex/react";
import { api } from "../../convex/_generated/api";

const SignupModal = ({ isOpen, onClose }) => {
  const { isLoaded, signUp, setActive } = useSignUp();
  const createProfile = useMutation(api.users.createProfile);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [venmoUsername, setVenmoUsername] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [pendingVerification, setPendingVerification] = useState(false);
  const [code, setCode] = useState("");

  if (!isOpen) return null;
  if (!isLoaded) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      // Start the signup process and request email verification
      await signUp.create({
        firstName,
        lastName,
        emailAddress: email,
        username,
        password,
      });

      // Prepare verification
      await signUp.prepareEmailAddressVerification({ strategy: "email_code" });

      // Set pending verification state to show verification form
      setPendingVerification(true);
    } catch (err) {
      console.error("Error during signup:", err);
      setError(err.message || "An error occurred during signup");
    } finally {
      setLoading(false);
    }
  };

  const verifyEmail = async (e) => {
    e.preventDefault();
    setVerifying(true);
    setError("");

    try {
      const response = await signUp.attemptEmailAddressVerification({
        code,
      });

      if (response.status === "complete") {
        // Create user profile in Convex
        try {
          await createProfile({
            username,
            email,
            name: `${firstName} ${lastName}`,
            venmo: venmoUsername,
          });
          await setActive({ session: response.createdSessionId });
          onClose();
        } catch (profileError) {
          console.error("Error creating profile:", profileError);
          setError(
            "Account created but there was an error setting up your profile. Please contact support."
          );
          // Still set the user as active even if profile creation fails
          await setActive({ session: response.createdSessionId });
        }
      } else {
        setError("Email verification failed. Please try again.");
      }
    } catch (err) {
      setError(err.message || "Error verifying email");
    } finally {
      setVerifying(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="w-full max-w-md rounded-xl bg-gray-800 shadow-2xl ring-1 ring-white/20">
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-white">
            {pendingVerification ? "Verify Email" : "Create Account"}
          </h2>
          <button
            onClick={onClose}
            className="text-sm font-medium text-gray-400 hover:text-white transition-colors"
          >
            Close
          </button>
        </div>

        {!pendingVerification ? (
          <form
            onSubmit={handleSubmit}
            className="p-6 space-y-6"
            autoComplete="off"
            spellCheck="false"
          >
            {/* Name Fields */}
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label
                  htmlFor="firstName"
                  className="text-sm font-medium text-gray-300"
                >
                  First Name
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="firstName"
                    type="text"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="John"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label
                  htmlFor="lastName"
                  className="text-sm font-medium text-gray-300"
                >
                  Last Name
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="lastName"
                    type="text"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Doe"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                  />
                </div>
              </div>
            </div>

            {/* Email Field */}
            <div className="space-y-2">
              <label
                htmlFor="email"
                className="text-sm font-medium text-gray-300"
              >
                Email
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="john.doe@example.com"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
            </div>

            {/* Password Field */}
            <div className="space-y-2">
              <label
                htmlFor="password"
                className="text-sm font-medium text-gray-300"
              >
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full pl-3 pr-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Create a password"
                  autoComplete="new-password"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5 text-gray-400 hover:text-gray-300" />
                  ) : (
                    <Eye className="h-5 w-5 text-gray-400 hover:text-gray-300" />
                  )}
                </button>
              </div>
            </div>

            {/* Username Field */}
            <div className="space-y-2">
              <label
                htmlFor="username"
                className="text-sm font-medium text-gray-300"
              >
                Username
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="username"
                  name="username_display"
                  type="text"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  data-form-type="other"
                  className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Choose your display name"
                />
              </div>
              <p className="text-xs text-gray-400">
                This is how you'll appear on the leaderboard
              </p>
            </div>

            {/* Venmo Username Field */}
            <div className="space-y-2">
              <label
                htmlFor="venmo"
                className="text-sm font-medium text-gray-300"
              >
                Venmo Username
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <CreditCard className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="venmo"
                  type="text"
                  required
                  value={venmoUsername}
                  onChange={(e) => setVenmoUsername(e.target.value)}
                  className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="@your-venmo-username"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
              <p className="text-xs text-gray-400">
                This will be used for pool payments
              </p>
            </div>

            {error && <p className="text-red-400 text-sm mt-2">{error}</p>}

            <button
              type="submit"
              disabled={loading}
              className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200 ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {loading ? "Creating Account..." : "Create Account"}
            </button>
          </form>
        ) : (
          <form onSubmit={verifyEmail} className="p-6 space-y-6">
            <p className="text-gray-300">
              Please check your email for a verification code.
            </p>
            <div className="space-y-2">
              <label
                htmlFor="code"
                className="text-sm font-medium text-gray-300"
              >
                Verification Code
              </label>
              <input
                id="code"
                type="text"
                required
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="block w-full rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter verification code"
              />
            </div>

            {error && <p className="text-red-400 text-sm mt-2">{error}</p>}

            <button
              type="submit"
              disabled={verifying}
              className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200 ${
                verifying ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {verifying ? "Verifying..." : "Verify Email"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SignupModal;
