import React, { useState, useEffect, useCallback, useTransition } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useAuth, useUser, useClerk } from "@clerk/clerk-react";
import LoginComponent from "./components/LoginComponent";
import HomePage from "./components/HomePage";
import LeaderboardPage from "./components/LeaderboardPage";
import ProfilePage from "./components/ProfilePage";
import ScoresPage from "./components/ScoresPage";
import ScrollToTop from "./components/ScrollToTop";
import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css"; //default toastify styles
import "./toastify.css"; // Add this line to import your custom styles
import Layout from "./Layout";
import AccountSettingsPage from "./components/AccountSettingsPage";
import SignupModal from "./components/SignupModal";
import MaintenancePage from "./components/MaintenancePage";
import PasswordResetPage from "./components/PasswordResetPage";
import UpdatePasswordPage from "./components/UpdatePasswordPage";
import { getGamesForWeek } from "./gameData";
import { getCurrentWeek } from "./components/weekDates";
import { DialogProvider } from "./context/DialogContext";
import ConvexTester from "./components/ConvexTester";
import { useMutation, useQuery } from "convex/react";
import { api } from "../convex/_generated/api";
import { Protect } from "@clerk/clerk-react";
import { StrictMode } from 'react';
import ErrorBoundary from "./components/ErrorBoundary";

const REFRESH_THRESHOLD = 20 * 60 * 1000; // 20 minutes in milliseconds

const LoadingScreen = () => (
  <div className="flex items-center justify-center min-h-screen bg-gray-900">
    <img
      src="https://utfs.io/f/7P1pSdZBrdR0FgO9FWUNWSIUAYLoKjlQ5nqeu9ygEHwsMhar"
      alt="Logo"
      className="h-24 w-24 rounded-full shadow-lg animate-pulse "
    />
  </div>
);

// Create a new component for the main app content
const AppContent = () => {
  const { isLoaded, isSignedIn } = useAuth();
  const { user, isLoaded: isUserLoaded } = useUser();
  const { signOut, session } = useClerk();
  const [isPending, startTransition] = useTransition();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isScrollable, setIsScrollable] = useState(true);
  const [hideBottomNav, setHideBottomNav] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [isMaintenanceMode] = useState(false);
  const [hasSubmittedAllPicks, setHasSubmittedAllPicks] = useState(true);
  const [lastKnownUsername, setLastKnownUsername] = useState("");

  const handleRouteChange = useCallback((to) => {
    startTransition(() => {
      navigate(to);
    });
  }, [navigate, startTransition]);

  const getUserWeekPicks = useQuery(api.picks.getUserWeekPicks, {
    username: user?.username || "",
    week: getCurrentWeek().toString(),
  });

  useEffect(() => {
    const initializeTimes = () => {
      const startTime =
        localStorage.getItem("startTime") || Date.now().toString();
      localStorage.setItem("startTime", startTime);
      localStorage.setItem("lastVisit", Date.now().toString());
    };

    initializeTimes();

    const updateLastVisit = () => {
      localStorage.setItem("lastVisit", Date.now().toString());
    };

    // Update last visit time periodically without full refresh
    const interval = setInterval(updateLastVisit, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  const handleSignOut = useCallback(async () => {
    try {
      // Remove stored username before signing out
      localStorage.removeItem("clerk_username");
      await signOut();
      // Remove any stored data
      localStorage.clear();
      // Force a clean reload of the application
      window.location.replace("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  }, [signOut]);

  useEffect(() => {
    if (isScrollable) {
      document.body.classList.remove("no-scroll");
    } else {
      document.body.classList.add("no-scroll");
    }
  }, [isScrollable]);

  const closeSignupModal = () => setIsSignupModalOpen(false);

  const handleSignup = (newUsername) => {
    console.log("Setting new username:", newUsername); // Add this log
    setUsername(newUsername);
    closeSignupModal();
  };

  // Add this function to check if all picks are submitted
  const checkAllPicksSubmitted = useCallback(() => {
    if (!isSignedIn || !user?.username || !getUserWeekPicks) return;

    try {
      const userPicks = getUserWeekPicks;

      // If no picks exist, set hasSubmittedAllPicks to false
      if (!userPicks || userPicks.length === 0) {
        setHasSubmittedAllPicks(false);
        return;
      }

      const picks =
        typeof userPicks[0].picks === "string"
          ? JSON.parse(userPicks[0].picks)
          : userPicks[0].picks;

      const games = getGamesForWeek(getCurrentWeek());

      // Check if all games have valid picks
      const allPicksSubmitted = games.every((_, index) => {
        const pick = picks[index];
        return pick && pick !== "Not Submitted";
      });

      setHasSubmittedAllPicks(allPicksSubmitted);
    } catch (error) {
      console.error("Error checking picks submission:", error);
      setHasSubmittedAllPicks(false);
    }
  }, [isSignedIn, user?.username, getUserWeekPicks]);

  // Add this effect to run the check whenever picks data changes
  useEffect(() => {
    checkAllPicksSubmitted();
  }, [checkAllPicksSubmitted, getUserWeekPicks]);

  // Add username check effect
  useEffect(() => {
    if (isSignedIn && user && user.username !== lastKnownUsername) {
      // Only update if the username has changed
      setLastKnownUsername(user.username);

      // Store the current username in localStorage for comparison
      const storedUsername = localStorage.getItem("clerk_username");
      if (storedUsername !== user.username) {
        localStorage.setItem("clerk_username", user.username);
        // Only reload if this isn't the first time setting the username
        if (storedUsername) {
          console.log("Username changed, refreshing...");
          window.location.reload();
        }
      }
    }
  }, [isSignedIn, user, lastKnownUsername]);

  if (!isLoaded || !isUserLoaded) {
    return <LoadingScreen />;
  }

  if (isLoaded && !isSignedIn) {
    return <LoginComponent />;
  }

  if (isMaintenanceMode) {
    return <MaintenancePage />;
  }

  return (
    <StrictMode>
      <ErrorBoundary>
        <DialogProvider>
          <ScrollToTop />
          <Routes>
            <Route path="/password-reset" element={<PasswordResetPage />} />
            <Route path="/reset-password" element={<UpdatePasswordPage />} />
            <Route
              path="/*"
              element={
                <Layout
                  username={user?.username}
                  onLogout={handleSignOut}
                  hideBottomNav={hideBottomNav}
                  avatarUrl={user?.imageUrl}
                  hasSubmittedAllPicks={hasSubmittedAllPicks}
                  isPending={isPending}
                >
                  <Routes>
                    <Route
                      path="/"
                      element={<Navigate to="/leaderboard" replace />}
                    />
                    <Route
                      path="/scores"
                      element={<ScoresPage username={user?.username} />}
                    />
                    <Route
                      path="/home"
                      element={
                        <HomePage
                          username={user?.username}
                          setIsScrollable={setIsScrollable}
                          setHideBottomNav={setHideBottomNav}
                          setHasSubmittedAllPicks={setHasSubmittedAllPicks}
                        />
                      }
                    />
                    <Route path="/leaderboard" element={<LeaderboardPage />} />
                    <Route
                      path="/profile"
                      element={<ProfilePage userName={user?.username} />}
                    />
                    <Route
                      path="/account-settings"
                      element={<AccountSettingsPage userName={user?.username} />}
                    />
                    <Route
                      path="/dev"
                      element={<ConvexTester username={user?.username} />}
                    />
                    <Route
                      path="*"
                      element={<Navigate to="/leaderboard" replace />}
                    />
                  </Routes>
                </Layout>
              }
            />
          </Routes>
          <SignupModal
            isOpen={isSignupModalOpen}
            onClose={closeSignupModal}
            onSignup={handleSignup}
          />
          <ToastContainer
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
            theme="dark"
            limit={4}
            stacked
            transition:Bounce
            className="Toastify__toast-container"
            toastClassName="Toastify__toast"
            bodyClassName="Toastify__toast-body"
            progressClassName="Toastify__progress-bar"
          />
        </DialogProvider>
      </ErrorBoundary>
    </StrictMode>
  );
};

// Main App component
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
