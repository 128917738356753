import { useState, useEffect } from "react";
import { useQuery, useMutation } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { getCurrentWeek } from "./weekDates";

const ConvexTester = ({ username: defaultUsername }) => {
  const [week, setWeek] = useState(getCurrentWeek().toString());
  const [editedPicks, setEditedPicks] = useState(null);
  const [selectedUser, setSelectedUser] = useState(defaultUsername);

  // Queries
  const allProfiles = useQuery(api.users.getAllProfiles);
  const profile = useQuery(api.users.getProfile, { username: selectedUser });
  const weekPicks = useQuery(api.picks.getWeekPicks, { week });
  const userPicks = useQuery(api.picks.getUserPicks, {
    username: selectedUser,
  });
  const userWeekPicks = useQuery(api.picks.getUserWeekPicks, {
    username: selectedUser,
    week,
  });
  const allPicks = useQuery(api.picks.getAllUserPicks);

  // Mutation
  const updatePicks = useMutation(api.picks.updateUserWeekPicks);

  // Set initial selected user when profiles are loaded
  useEffect(() => {
    if (allProfiles && allProfiles.length > 0 && !selectedUser) {
      setSelectedUser(allProfiles[0].username);
    }
  }, [allProfiles, selectedUser]);

  const handleExport = () => {
    const exportData = {
      profile: profile,
      weekPicks: weekPicks,
      userPicks: userPicks,
      userWeekPicks: userWeekPicks,
      allPicks: allPicks,
    };

    const blob = new Blob([JSON.stringify(exportData, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `convex-data-export-${new Date().toISOString()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleSave = async (updatedPicks) => {
    if (!updatedPicks) return;

    try {
      await updatePicks({
        username: selectedUser,
        week,
        picks: updatedPicks,
      });
      console.log("Picks updated successfully!");
    } catch (error) {
      console.error("Error updating picks:", error);
    }
  };

  return (
    <div className="pt-6">
      <Card className="bg-background border-border shadow-lg">
        <CardHeader className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0 px-4 sm:px-6 py-4 border-b">
          <div>
            <CardTitle className="text-xl sm:text-2xl font-bold text-foreground">
              Convex Data Viewer
            </CardTitle>
            <p className="text-sm text-muted-foreground mt-1">
              View and manage user picks data
            </p>
          </div>
          <Button
            onClick={handleExport}
            variant="outline"
            className="w-full sm:w-auto flex items-center justify-center gap-2"
          >
            <span>Export All Data</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
              <polyline points="7 10 12 15 17 10" />
              <line x1="12" y1="15" x2="12" y2="3" />
            </svg>
          </Button>
        </CardHeader>
        <CardContent className="space-y-6 px-4 sm:px-6 py-4">
          {/* Controls Section */}
          <div className="flex flex-col gap-4 p-4 bg-muted/50 rounded-lg">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="user-select" className="text-sm font-medium">
                  Select User
                </Label>
                <Select value={selectedUser} onValueChange={setSelectedUser}>
                  <SelectTrigger
                    id="user-select"
                    className="bg-background border-input"
                  >
                    <SelectValue placeholder="Select user" />
                  </SelectTrigger>
                  <SelectContent
                    position="popper"
                    className="w-[--radix-select-trigger-width]"
                  >
                    {allProfiles?.map((profile) => (
                      <SelectItem
                        key={profile.username}
                        value={profile.username}
                        className="cursor-pointer hover:bg-accent"
                      >
                        {profile.username}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="week-select" className="text-sm font-medium">
                  Select Week
                </Label>
                <Select value={week} onValueChange={setWeek}>
                  <SelectTrigger
                    id="week-select"
                    className="bg-background border-input"
                  >
                    <SelectValue placeholder="Select week" />
                  </SelectTrigger>
                  <SelectContent
                    position="popper"
                    className="w-[--radix-select-trigger-width]"
                  >
                    {Array.from({ length: 18 }, (_, i) => i + 1).map(
                      (weekNum) => (
                        <SelectItem
                          key={weekNum}
                          value={weekNum.toString()}
                          className="cursor-pointer hover:bg-accent"
                        >
                          Week {weekNum}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>

          {/* Data Sections */}
          <div className="grid gap-4">
            <DataSection
              title={`Picks for ${selectedUser || "No user"} - Week ${week}`}
              data={userWeekPicks}
              editable={true}
              onEdit={setEditedPicks}
              onSave={handleSave}
              loading={userWeekPicks === undefined}
            />
            <DataSection
              title={`All Picks for ${selectedUser || "No user"}`}
              data={userPicks}
              loading={userPicks === undefined}
            />
            <DataSection
              title={`Profile Data for ${selectedUser || "Not signed in"}`}
              data={profile}
              loading={profile === undefined}
            />

            <DataSection
              title={`All Users Picks for Week ${week}`}
              data={weekPicks}
              loading={weekPicks === undefined}
            />
            <DataSection
              title="All Picks (All Users, All Weeks)"
              data={allPicks}
              loading={allPicks === undefined}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const DataSection = ({ title, data, editable, onEdit, onSave, loading }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [editableContent, setEditableContent] = useState("");
  const [localData, setLocalData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // If no data exists, set default empty picks structure and enable editing
    if (!data || data.length === 0) {
      const defaultPicks = {
        0: "",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
      };
      setEditableContent(JSON.stringify(defaultPicks, null, 2));
      setIsEditing(true); // Only set to true if no data exists
    } else {
      // If data exists, initialize content but keep editing mode off
      if (data[0]?.picks) {
        try {
          const picksData =
            typeof data[0].picks === "string"
              ? JSON.parse(data[0].picks)
              : data[0].picks;
          setEditableContent(JSON.stringify(picksData, null, 2));
        } catch (e) {
          setEditableContent(JSON.stringify(data[0].picks, null, 2));
        }
      } else {
        setEditableContent(JSON.stringify(data, null, 2));
      }
      setLocalData(data);
      setIsEditing(false); // Ensure editing is off for existing data
    }
  }, [data]);

  const handleEdit = async () => {
    try {
      setError(null);
      const parsed = JSON.parse(editableContent);

      if (!localData || localData.length === 0) {
        const newPicksData = [{ picks: JSON.stringify(parsed) }];
        await onSave(newPicksData);
        setIsEditing(false);
        return;
      }

      const { _id, _creationTime, ...cleanData } = localData[0];
      const picksData = [{ ...cleanData, picks: JSON.stringify(parsed) }];

      setIsEditing(false);
      await onSave(picksData);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSectionExport = () => {
    const exportData = localData || data;
    const sanitizedTitle = title.replace(/[^a-z0-9]/gi, "-").toLowerCase();

    const blob = new Blob([JSON.stringify(exportData, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${sanitizedTitle}-${new Date().toISOString()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <Card className="bg-card border-border transition-all duration-200">
      <CardHeader
        className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0 px-4 py-3 cursor-pointer hover:bg-muted/50"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-2 w-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`transform transition-transform flex-shrink-0 ${
              isExpanded ? "rotate-90" : ""
            }`}
          >
            <polyline points="9 18 15 12 9 6" />
          </svg>
          <CardTitle className="text-base sm:text-lg text-card-foreground break-words">
            {title}
          </CardTitle>
        </div>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleSectionExport();
          }}
          variant="outline"
          size="sm"
          className="text-xs w-full sm:w-auto mt-2 sm:mt-0"
        >
          Export
        </Button>
      </CardHeader>
      {isExpanded && (
        <CardContent className="px-4 py-3 space-y-4">
          {loading ? (
            <div className="flex items-center justify-center h-32 bg-muted rounded-lg">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-foreground"></div>
            </div>
          ) : isEditing ? (
            <div className="space-y-2">
              <textarea
                className="w-full min-h-[120px] max-h-[400px] p-3 font-mono text-sm bg-muted text-muted-foreground rounded-lg resize-y focus:outline-none focus:ring-2 focus:ring-ring"
                value={editableContent}
                onChange={(e) => setEditableContent(e.target.value)}
                spellCheck="false"
              />
              {error && (
                <div className="text-sm text-destructive bg-destructive/10 p-2 rounded">
                  {error}
                </div>
              )}
            </div>
          ) : (
            <pre className="w-full whitespace-pre-wrap break-all text-sm bg-muted text-muted-foreground p-3 rounded-lg overflow-x-auto max-h-[400px] scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-muted-foreground/20 hover:scrollbar-thumb-muted-foreground/30">
              {JSON.stringify(localData, null, 2)}
            </pre>
          )}

          {/* Action Buttons */}
          {editable && !loading && (
            <div className="flex flex-col sm:flex-row sm:justify-end space-y-2 sm:space-y-0 sm:space-x-2 pt-2">
              {isEditing ? (
                <>
                  <Button
                    onClick={handleEdit}
                    variant="outline"
                    size="sm"
                    className="w-full sm:w-auto"
                  >
                    {!data || data.length === 0 ? "Submit" : "Save"}
                  </Button>
                  {data && data.length > 0 && (
                    <Button
                      onClick={() => {
                        setIsEditing(false);
                        setError(null);
                        if (localData && localData[0]?.picks) {
                          try {
                            const picksData =
                              typeof localData[0].picks === "string"
                                ? JSON.parse(localData[0].picks)
                                : localData[0].picks;
                            setEditableContent(
                              JSON.stringify(picksData, null, 2)
                            );
                          } catch (e) {
                            setEditableContent(
                              JSON.stringify(localData[0].picks, null, 2)
                            );
                          }
                        }
                      }}
                      variant="outline"
                      size="sm"
                      className="w-full sm:w-auto"
                    >
                      Cancel
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  onClick={() => setIsEditing(true)}
                  variant="outline"
                  size="sm"
                  className="w-full sm:w-auto"
                >
                  Edit
                </Button>
              )}
            </div>
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default ConvexTester;
