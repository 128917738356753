import React, { useEffect, useState } from "react";
import "../styles/snow.css";

const Snow = () => {
  const [snowflakes, setSnowflakes] = useState([]);

  useEffect(() => {
    // Create initial snowflakes
    const createSnowflakes = () => {
      const flakes = Array.from({ length: 50 }, (_, i) => ({
        id: i,
        left: Math.random() * 100,
        animationDuration: 9 + Math.random() * 10,
        opacity: 0.05 + Math.random() * 0.05, // Reduced opacity for lighter snowflakes
        size: 2 + Math.random() * 4, // Reduced size for smaller snowflakes
      }));
      setSnowflakes(flakes);
    };

    createSnowflakes();
  }, []);

  return (
    <div className="fixed inset-0 pointer-events-none z-0">
      {snowflakes.map((flake) => (
        <div
          key={flake.id}
          className="absolute animate-fall"
          style={{
            left: `${flake.left}%`,
            animationDuration: `${flake.animationDuration}s`,
            opacity: flake.opacity,
            width: `${flake.size}px`,
            height: `${flake.size}px`,
          }}
        >
          <div className="w-full h-full bg-white rounded-full" />
        </div>
      ))}
    </div>
  );
};

export default Snow;
