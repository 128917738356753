import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ForestIcon from "@mui/icons-material/Forest";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import BugReportIcon from "@mui/icons-material/BugReport";
import { useDialog } from "../context/DialogContext";

const ChristmasBottomNav = ({ isDevAuthorized }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const { isDialogOpen } = useDialog();

  const isProfilePage = location.pathname === "/profile";

  const navItems = useMemo(
    () => [
      {
        path: "/home",
        icon: CardGiftcardIcon,
        label: "Picks",
        color: "#ff4d4d",
      },
      {
        path: "/leaderboard",
        icon: ForestIcon,
        label: "Leaderboard",
        color: "#2ecc71",
      },
      {
        path: "/scores",
        icon: AcUnitIcon,
        label: "Scores",
        color: "#3498db",
      },
      ...(isDevAuthorized
        ? [
            {
              path: "/dev",
              icon: BugReportIcon,
              label: "Dev Tools",
              color: "#9b59b6",
            },
          ]
        : []),
    ],
    [isDevAuthorized]
  );

  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
      navigate(navItems[newValue].path);
    },
    [navigate, navItems]
  );

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    if (currentScrollY < 10) {
      setIsVisible(true);
    } else if (currentScrollY > lastScrollY) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const currentIndex = navItems.findIndex(
      (item) => item.path === location.pathname
    );
    if (currentIndex !== -1 && !isProfilePage) {
      setValue(currentIndex);
    } else {
      setValue(-1);
    }
  }, [location.pathname, navItems, isProfilePage]);

  return (
    <nav
      className={`bottom-nav fixed left-0 right-0 bottom-0 z-[9999] bg-white/10 dark:bg-gray-950/50 backdrop-blur-lg border-t border-white/20 transition-transform duration-300 overflow-hidden
        ${isVisible && !isDialogOpen ? "translate-y-0" : "translate-y-full"}`}
      style={{
        paddingBottom: "env(safe-area-inset-bottom)",
        height: "calc(64px + env(safe-area-inset-bottom))",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        willChange: "transform",
      }}
      aria-label="Christmas Bottom Navigation"
    >
      <BottomNavigation
        value={isProfilePage ? -1 : value}
        onChange={handleChange}
        showLabels
        sx={{
          height: "64px",
          backgroundColor: "transparent",
          "& .MuiBottomNavigationAction-root": {
            color: "rgb(255 255 255 / 0.7)",
            fontFamily: "inherit",
            fontSize: "0.75rem",
            minWidth: "auto",
            padding: "6px 0",
            position: "relative",
          },
        }}
      >
        {navItems.map((item, index) => (
          <BottomNavigationAction
            key={item.path}
            icon={
              <item.icon
                fontSize="medium"
                sx={{
                  fontSize: 24,
                  color:
                    !isProfilePage && value === index ? item.color : "inherit",
                  filter:
                    !isProfilePage && value === index
                      ? "drop-shadow(0 0 8px rgba(255, 255, 255, 0.3))"
                      : "none",
                }}
              />
            }
            label={item.label}
            disableRipple
            aria-label={`${item.label} page`}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "transparent",
                color: item.color,
              },
              "& .MuiSvgIcon-root": {
                transition: "all 0.3s ease",
              },
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "6px",
                left: "20%",
                width: "60%",
                height: "2px",
                background:
                  !isProfilePage && value === index
                    ? `linear-gradient(90deg, ${item.color} 0%, #f8f8f8 100%)`
                    : "none",
                opacity: !isProfilePage && value === index ? 1 : 0,
                transform:
                  !isProfilePage && value === index ? "scaleX(1)" : "scaleX(0)",
                transition:
                  "transform 0.3s ease-in-out, opacity 0.2s ease-in-out",
                borderRadius: "4px",
              },
              "&:hover": {
                "& .MuiSvgIcon-root": {
                  transform: "scale(1.1)",
                },
              },
              "& .MuiBottomNavigationAction-label": {
                transition: "all 0.3s ease",
                textShadow:
                  !isProfilePage && value === index
                    ? `0 0 10px ${item.color}40`
                    : "none",
                color:
                  !isProfilePage && value === index ? item.color : "inherit",
              },
              "&.Mui-selected .MuiBottomNavigationAction-label": {
                fontSize: "0.75rem",
              },
            }}
          />
        ))}
      </BottomNavigation>
    </nav>
  );
};

export default React.memo(ChristmasBottomNav);
