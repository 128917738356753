import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  Trophy,
  User,
  ArrowRight,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  Search,
  X,
  Calendar,
  Check,
} from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFootball } from "@fortawesome/free-solid-svg-icons";
import {
  getGamesForWeek,
  updateScores,
  GAME_STATUS,
  getGameStatus,
} from "../gameData";
import { weekDates, getLatestAvailableWeek, getCurrentWeek } from "./weekDates";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import SkeletonLoader from "./SkeletonLoader";
import { motion, AnimatePresence } from "motion/react";

import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";

const USE_TEST_DATA = false;

const TEST_GAMES = [
  {
    away: {
      abbreviation: "KC",
      spread: "-3.5",
      record: "8-3",
    },
    home: {
      abbreviation: "GB",
      spread: "+3.5",
      record: "5-6",
    },
    startTime: new Date().toISOString(),
    awayScore: 24,
    homeScore: 17,
    status: "LIVE",
    period: 3,
    clock: "4:20",
    possession: "KC",
    downDistance: "2nd & 7 at GB 35",
    lastPlay: "P.Mahomes pass complete ...",
    winner: null,
  },
];

const teamNames = {
  NE: "New England Patriots",
  BUF: "Buffalo Bills",
  MIA: "Miami Dolphins",
  NYJ: "New York Jets",
  BAL: "Baltimore Ravens",
  CIN: "Cincinnati Bengals",
  CLE: "Cleveland Browns",
  PIT: "Pittsburgh Steelers",
  HOU: "Houston Texans",
  IND: "Indianapolis Colts",
  JAX: "Jacksonville Jaguars",
  TEN: "Tennessee Titans",
  DEN: "Denver Broncos",
  KC: "Kansas City Chiefs",
  LV: "Las Vegas Raiders",
  LAC: "Los Angeles Chargers",
  DAL: "Dallas Cowboys",
  NYG: "New York Giants",
  PHI: "Philadelphia Eagles",
  WAS: "Washington Commanders",
  CHI: "Chicago Bears",
  DET: "Detroit Lions",
  GB: "Green Bay Packers",
  MIN: "Minnesota Vikings",
  ATL: "Atlanta Falcons",
  CAR: "Carolina Panthers",
  NO: "New Orleans Saints",
  TB: "Tampa Bay Buccaneers",
  ARI: "Arizona Cardinals",
  LAR: "Los Angeles Rams",
  SF: "San Francisco 49ers",
  SEA: "Seattle Seahawks",
};

const getTeamName = (abbreviation) => {
  return teamNames[abbreviation] || abbreviation;
};

const SearchBar = ({ isOpen, setIsOpen, searchQuery, setSearchQuery }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery("");
    }
  }, [isOpen, setSearchQuery]);

  const handleClear = () => {
    setSearchQuery("");
    inputRef.current?.focus();
  };

  return (
    <div className="flex items-center gap-2">
      <div
        className={cn(
          "flex items-center transition-all duration-300",
          isOpen ? "w-[200px]" : "w-8"
        )}
      >
        {isOpen ? (
          <div className="flex items-center w-full relative">
            <Input
              ref={inputRef}
              type="text"
              placeholder="Search teams..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-8 pr-10 bg-gray-800 border-gray-700 text-gray-100"
            />
            <Search className="w-4 h-4 absolute left-2.5 text-gray-400" />
            {searchQuery && (
              <Button
                variant="ghost"
                size="icon"
                onClick={handleClear}
                className="h-8 w-8 absolute right-1 text-gray-400 hover:text-gray-300 hover:bg-gray-700"
              >
                <X className="h-4 w-4" />
              </Button>
            )}
          </div>
        ) : (
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsOpen(true)}
            className="h-8 w-8 text-gray-400 hover:text-gray-300"
          >
            <Search className="h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  );
};

const WeekSelector = ({ selectedWeek, handleWeekChange }) => {
  const weeks = Object.keys(weekDates).map(Number);
  const [open, setOpen] = useState(false);

  return (
    <div className="flex items-center justify-center space-x-4 mb-6">
      <Button
        variant="base"
        size="icon"
        onClick={() => handleWeekChange(selectedWeek - 1)}
        disabled={!weekDates[selectedWeek - 1]}
        className="h-10 w-10"
      >
        <ChevronLeft className="h-5 w-5 text-gray-300" />
      </Button>

      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="base"
            role="combobox"
            className={cn(
              "w-[200px] h-10 text-base justify-between",
              !weekDates[selectedWeek] && "text-muted-foreground"
            )}
          >
            <span className="flex items-center gap-2 text-gray-300">
              <Calendar className="h-5 w-5" />
              Week {selectedWeek}
            </span>
            <ChevronDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0 bg-gray-800 border-gray-700">
          <div className="max-h-[300px] overflow-y-auto">
            {weeks.map((week) => (
              <button
                key={week}
                onClick={() => {
                  handleWeekChange(week);
                  setOpen(false);
                }}
                disabled={!weekDates[week]}
                className={cn(
                  "w-full flex items-center justify-between px-4 py-3 text-base text-gray-300 lg:hover:bg-gray-700 disabled:text-gray-500 disabled:hover:bg-transparent",
                  !weekDates[week] && "text-gray-500",
                  selectedWeek === week && "bg-gray-700"
                )}
              >
                <span>Week {week}</span>
                {selectedWeek === week && (
                  <Check className="h-5 w-5 text-blue-400" />
                )}
              </button>
            ))}
          </div>
        </PopoverContent>
      </Popover>

      <Button
        variant="base"
        size="icon"
        onClick={() => handleWeekChange(selectedWeek + 1)}
        disabled={!weekDates[selectedWeek + 1]}
        className="h-10 w-10"
      >
        <ChevronRight className="h-5 w-5 text-gray-300" />
      </Button>
    </div>
  );
};

const FootballField = ({ downInfo, homeTeam, awayTeam, possession }) => {
  const getYardLine = (downInfo) => {
    const match = downInfo.match(/at (\w+) (\d+)/);
    if (match) {
      const side = match[1].toLowerCase();
      const yardLine = parseInt(match[2], 10);
      if (side === homeTeam.toLowerCase()) {
        return 100 - yardLine;
      } else {
        return yardLine;
      }
    }
    return 50;
  };

  const yardLine = getYardLine(downInfo);
  const isHomePossession = possession === homeTeam;
  const isRedZone =
    (isHomePossession && yardLine <= 10) ||
    (!isHomePossession && yardLine >= 90);

  return (
    <div className="space-y-1">
      <div
        className={`text-white text-xs text-center py-1 rounded-t-md ${
          isRedZone ? "bg-red-600" : "bg-gray-700 bg-opacity-90"
        }`}
      >
        {downInfo}
      </div>
      <div className="flex w-full h-10 rounded-b-md overflow-hidden">
        <div className="w-[5%] h-full bg-red-600 opacity-50 flex items-center justify-center">
          <span className="text-white text-xs font-bold transform rotate-90">
            {awayTeam}
          </span>
        </div>
        <div className="relative w-[90%] h-full bg-green-800 border-t-2 border-b-2 border-white">
          {[10, 20, 30, 40, 50, 60, 70, 80, 90].map((marker) => (
            <div
              key={marker}
              className={`absolute top-0 bottom-0 w-px ${
                marker === 50 ? "bg-white" : "bg-white opacity-30"
              }`}
              style={{ left: `calc(${marker}% - 1px)` }}
            />
          ))}

          {[10, 20, 30, 40, 50, 40, 30, 20, 10].map((number, index) => (
            <div
              key={index}
              className={`absolute top-0 transform -translate-y-1/2 text-white text-xs font-bold ${
                number === 50 ? "" : "opacity-70"
              }`}
              style={{
                left: `${index * 10 + 10}%`,
                transform: "translateX(-50%)",
              }}
            >
              {number}
            </div>
          ))}

          <div
            className="absolute top-3/4 transform -translate-y-1/2 w-2 h-2 bg-yellow-400 rounded-full transition-all duration-300 ease-in-out"
            style={{
              left: `calc(${yardLine}% - 4.5px)`,
              boxShadow: "0 0 10px 2px rgba(250, 204, 21, 0.7)",
            }}
          />

          {possession && (
            <div
              className="absolute top-3/4 transform -translate-y-1/2"
              style={{
                left: isHomePossession
                  ? `calc(${yardLine}% - 35px)`
                  : `calc(${yardLine}% - 5px)`,
              }}
            >
              {isHomePossession ? (
                <ArrowLeft className="w-10 h-4 text-yellow-400" />
              ) : (
                <ArrowRight className="w-10 h-4 text-yellow-400" />
              )}
            </div>
          )}
        </div>
        <div className="w-[5%] h-full bg-blue-600 opacity-50 flex items-center justify-center">
          <span className="text-white text-xs font-bold transform -rotate-90">
            {homeTeam}
          </span>
        </div>
      </div>
    </div>
  );
};

const ScoreDisplay = ({ score, isUpdated, isWinner }) => {
  return (
    <div className="relative w-8 h-8 flex items-center justify-end overflow-visible">
      <AnimatePresence>
        {isUpdated && (
          <motion.div
            key={`highlight-${score}`}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 0.5, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.4 }}
            className="absolute inset-0 rounded-full bg-blue-500"
            style={{
              filter: "blur(4px)",
            }}
          />
        )}
      </AnimatePresence>

      <motion.span
        key={`${score}-${isUpdated}`}
        initial={isUpdated ? { y: -20, opacity: 0, scale: 1.2 } : false}
        animate={{
          y: 0,
          opacity: 1,
          scale: 1,
          color: isUpdated ? "#3B82F6" : isWinner ? "#22C55E" : "#FFFFFF",
        }}
        transition={{
          type: "spring",
          stiffness: 300,
          damping: 20,
          duration: 0.4,
        }}
        className="absolute font-semibold text-xl tabular-nums"
      >
        {score}
      </motion.span>
    </div>
  );
};

// Removed React.memo here so that it re-renders on score changes
const GameCard = ({
  game,
  userPick,
  allGamesCompleted,
  onGameStatusChange,
}) => {
  const [countdown, setCountdown] = useState("");
  const [localGameStatus, setLocalGameStatus] = useState(game.status);

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const gameStart = new Date(game.startTime);
      const timeUntilStart = gameStart - now;
      const oneHourInMs = 60 * 60 * 1000;

      if (timeUntilStart <= oneHourInMs && timeUntilStart > 0) {
        const minutes = Math.floor(
          (timeUntilStart % oneHourInMs) / (60 * 1000)
        );
        const seconds = Math.floor((timeUntilStart % (60 * 1000)) / 1000);
        setCountdown(`${minutes}:${seconds.toString().padStart(2, "0")}`);
      } else if (timeUntilStart <= 0 && game.status === "UPCOMING") {
        setLocalGameStatus("LIVE");
        setCountdown("");
      } else {
        setCountdown("");
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [game.startTime, game.status]);

  useEffect(() => {
    if (localGameStatus !== game.status) {
      onGameStatusChange?.(game, localGameStatus);
    }
  }, [localGameStatus, game, onGameStatusChange]);

  const isLive =
    getGameStatus({ ...game, status: localGameStatus }) === GAME_STATUS.LIVE;
  const isFinal =
    getGameStatus({ ...game, status: localGameStatus }) ===
    GAME_STATUS.COMPLETED;

  return (
    <Card
      className={cn(
        "relative overflow-hidden flex flex-col h-full",
        isLive && "border-blue-500"
      )}
    >
      <CardHeader className="space-y-0 p-4 pb-2">
        <div className="flex items-center justify-between">
          <CardDescription className="text-base font-medium text-foreground/70">
            {game.away.abbreviation} @ {game.home.abbreviation}
          </CardDescription>
          <CardDescription>
            {isLive ? (
              <Badge
                variant="destructive"
                className="font-medium text-[10px] px-2 py-0"
              >
                LIVE
              </Badge>
            ) : isFinal ? (
              <Badge
                variant="secondary"
                className="font-medium text-[10px] px-2 py-0"
              >
                FINAL
              </Badge>
            ) : countdown ? (
              <span className="text-yellow-500 text-sm font-medium">
                {countdown}
              </span>
            ) : (
              <span className="text-sm text-foreground/50">
                {new Date(game.startTime)
                  .toLocaleString("en-US", {
                    weekday: "short",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                    timeZone: "America/New_York",
                    hour12: true,
                  })
                  .replace(",", "")
                  .toUpperCase() + " EST"}
              </span>
            )}
          </CardDescription>
        </div>
      </CardHeader>

      <CardContent className="p-4 pt-0 space-y-3 flex-1">
        {[game.away, game.home].map((team, teamIndex) => {
          const isWinner =
            game.winner && game.winner.includes(team.abbreviation);
          const spread =
            team.spread === "E"
              ? "EVEN"
              : parseFloat(team.spread) > 0
                ? `+${team.spread}`
                : team.spread;
          const isUserPick = userPick && userPick.includes(team.abbreviation);
          const hasPossession = game.possession === team.abbreviation;

          return (
            <div key={teamIndex} className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <div className="w-10 h-10">
                  <img
                    src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${team.abbreviation.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=80&h=80`}
                    alt={team.abbreviation}
                    className="w-10 h-10"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center gap-2">
                    <span
                      className={cn(
                        "font-medium text-base",
                        isWinner ? "text-green-500" : "text-foreground"
                      )}
                    >
                      {team.abbreviation}
                    </span>
                    <span className="text-foreground/40 text-sm">
                      ({spread})
                    </span>
                    {hasPossession && (
                      <FontAwesomeIcon
                        icon={faFootball}
                        className="w-3 h-3 text-orange-500"
                      />
                    )}
                  </div>
                  <div className="flex items-center gap-1">
                    <span className="text-xs text-foreground/40">
                      {team.record || "N/A"}
                    </span>
                    <div className="flex items-center ml-2">
                      {isWinner && (
                        <div className="w-5 h-5 rounded-full bg-gray-900 border border-gray-700 shadow-lg flex items-center justify-center">
                          <Trophy
                            className="w-3 h-3 text-yellow-500"
                            strokeWidth={2.5}
                          />
                        </div>
                      )}
                      {isUserPick && (
                        <div
                          className={cn(
                            "w-5 h-5 rounded-full bg-gray-900 border border-gray-700 shadow-lg flex items-center justify-center",
                            isWinner ? "-ml-2" : ""
                          )}
                        >
                          <User
                            className="w-3 h-3 text-blue-500"
                            strokeWidth={2.5}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <ScoreDisplay
                  score={team === game.home ? game.homeScore : game.awayScore}
                  isUpdated={
                    team === game.home
                      ? game.homeScoreUpdated
                      : game.awayScoreUpdated
                  }
                  isWinner={isWinner}
                />
              </div>
            </div>
          );
        })}

        {game.downDistance &&
          game.downDistance !== "No down and distance info" && (
            <div className="pt-1">
              <FootballField
                downInfo={game.downDistance}
                homeTeam={game.home.abbreviation}
                awayTeam={game.away.abbreviation}
                possession={game.possession}
              />
            </div>
          )}
      </CardContent>

      {game.lastPlay && isLive && (
        <CardFooter className="px-3 py-2 bg-blue-500/5 flex flex-col items-start gap-1 mt-auto">
          <span className="text-xs text-blue-500 font-medium">
            {game.status}
          </span>
          <span className="text-xs text-muted-foreground break-words">
            {game.lastPlay}
          </span>
        </CardFooter>
      )}

      {isFinal && (
        <CardFooter className="px-3 py-1.5 bg-muted/50 mt-auto">
          <span className="text-xs w-full text-center font-medium text-foreground/50">
            {game.winner ? `Cover - ${game.winner}` : "FINAL"}
          </span>
        </CardFooter>
      )}

      {userPick === undefined && (
        <div className="absolute inset-0 bg-background/50 flex items-center justify-center">
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500" />
        </div>
      )}
    </Card>
  );
};

const ScoresPage = ({ username, userId }) => {
  const [games, setGames] = useState(USE_TEST_DATA ? TEST_GAMES : []);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState(getLatestAvailableWeek());
  const [userPicksState, setUserPicksState] = useState({});
  const [currentWeek, setCurrentWeek] = useState(getCurrentWeek());
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [artificialDelay, setArtificialDelay] = useState(false);

  const intervalRef = useRef(null);
  const searchRef = useRef(null);

  const prevGamesRef = useRef([]);

  const convexUserPicks = useQuery(api.picks.getUserWeekPicks, {
    username: username,
    week: selectedWeek.toString(),
  });

  const getDisplayStatus = useCallback((game) => {
    const status = getGameStatus(game);
    if (status === GAME_STATUS.COMPLETED) return "FINAL";
    if (status === GAME_STATUS.UPCOMING) return "UPCOMING";
    if (game.clock && game.period) return `Q${game.period} ${game.clock}`;
    return "LIVE";
  }, []);

  const updateGameStatuses = useCallback(() => {
    setGames((prevGames) =>
      prevGames.map((game) => ({
        ...game,
        status: getDisplayStatus(game),
      }))
    );
  }, [getDisplayStatus]);

  const fetchUserPicks = useCallback(async () => {
    try {
      if (!convexUserPicks) return {};
      if (convexUserPicks && convexUserPicks.length > 0) {
        const parsedPicks = JSON.parse(convexUserPicks[0].picks) || {};
        setUserPicksState(parsedPicks);
        return parsedPicks;
      }
      setUserPicksState({});
      return {};
    } catch (error) {
      console.error("Error parsing user picks:", error);
      setUserPicksState({});
      return {};
    }
  }, [convexUserPicks]);

  const fetchScores = useCallback(
    async (week) => {
      try {
        if (USE_TEST_DATA) {
          setShowSkeleton(false);
          return;
        }

        await updateScores(week);
        const fetchedGames = getGamesForWeek(week);

        // Determine which games have updated scores
        const updatedGames = fetchedGames.map((fetchedGame, i) => {
          const oldGame = prevGamesRef.current[i] || {};
          return {
            ...fetchedGame,
            awayScoreUpdated:
              oldGame.awayScore !== undefined &&
              oldGame.awayScore !== fetchedGame.awayScore,
            homeScoreUpdated:
              oldGame.homeScore !== undefined &&
              oldGame.homeScore !== fetchedGame.homeScore,
          };
        });

        setGames(updatedGames);
        prevGamesRef.current = updatedGames;
        localStorage.setItem(`gamesWeek${week}`, JSON.stringify(updatedGames));
        localStorage.setItem("lastFetchTime", Date.now().toString());
      } catch (error) {
        console.error("Error fetching scores:", error);
      } finally {
        if (convexUserPicks) {
          setShowSkeleton(false);
        }
      }
    },
    [convexUserPicks]
  );

  const filterGames = useCallback(
    (gamesToFilter) => {
      if (!searchQuery) return gamesToFilter;
      const searchTerms = searchQuery.toLowerCase().split(" ");
      return gamesToFilter.filter((game) => {
        const searchableContent = `
          ${game.away.abbreviation.toLowerCase()} 
          ${game.home.abbreviation.toLowerCase()}
          ${getTeamName(game.away.abbreviation).toLowerCase()}
          ${getTeamName(game.home.abbreviation).toLowerCase()}
        `;
        return searchTerms.every((term) => searchableContent.includes(term));
      });
    },
    [searchQuery]
  );

  const handleWeekChange = useCallback(
    (newWeek) => {
      if (weekDates[newWeek]) {
        setShowSkeleton(true);
        setArtificialDelay(true);
        setSelectedWeek(newWeek);

        setTimeout(() => {
          setArtificialDelay(false);
          fetchScores(newWeek);
        }, 500);
      }
    },
    [fetchScores]
  );

  const getUserPickForGame = useCallback(
    (game) => {
      const gameIndex = games.findIndex(
        (g) =>
          g.away.abbreviation === game.away.abbreviation &&
          g.home.abbreviation === game.home.abbreviation
      );
      return userPicksState[gameIndex] || null;
    },
    [games, userPicksState]
  );

  const handleGameStatusChange = useCallback((updatedGame, newStatus) => {
    setGames((prevGames) =>
      prevGames.map((game) =>
        game.away.abbreviation === updatedGame.away.abbreviation &&
        game.home.abbreviation === updatedGame.home.abbreviation
          ? { ...game, status: newStatus }
          : game
      )
    );
  }, []);

  useEffect(() => {
    if (convexUserPicks) {
      fetchUserPicks(selectedWeek);
    }
  }, [convexUserPicks, selectedWeek, fetchUserPicks]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setGames((games) =>
        games.map((game) => ({
          ...game,
          awayScoreUpdated: false,
          homeScoreUpdated: false,
          lastPlayUpdated: false,
          statusUpdated: false,
        }))
      );
    }, 1000);
    return () => clearTimeout(timer);
  }, [games]);

  useEffect(() => {
    if (USE_TEST_DATA) {
      setTimeout(() => setShowSkeleton(false), 300);
      return;
    }

    let isMounted = true;
    let fetchInterval = null;

    const fetchAndUpdateScores = async () => {
      if (!isMounted) return;
      try {
        await fetchScores(selectedWeek);
        updateGameStatuses();
        setCurrentWeek(getCurrentWeek());
      } catch (error) {
        console.error("Error fetching scores:", error);
      }
    };

    const cachedGames = localStorage.getItem(`gamesWeek${selectedWeek}`);
    const lastFetchTime = localStorage.getItem("lastFetchTime");
    const lastKnownWeek = localStorage.getItem("lastKnownWeek");
    const currentWeekNumber = getCurrentWeek();

    const shouldFetch =
      !lastFetchTime ||
      Date.now() - parseInt(lastFetchTime, 10) > 15000 ||
      !lastKnownWeek ||
      parseInt(lastKnownWeek, 10) !== currentWeekNumber;

    if (cachedGames && !shouldFetch) {
      const parsedCachedGames = JSON.parse(cachedGames);
      prevGamesRef.current = parsedCachedGames;
      setGames(parsedCachedGames);
      setTimeout(() => setShowSkeleton(false), 300);
    } else {
      setShowSkeleton(true);
      fetchAndUpdateScores();
    }

    localStorage.setItem("lastKnownWeek", currentWeekNumber);

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    fetchInterval = setInterval(fetchAndUpdateScores, 15000);
    intervalRef.current = fetchInterval;

    return () => {
      isMounted = false;
      if (fetchInterval) clearInterval(fetchInterval);
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [fetchScores, selectedWeek, updateGameStatuses]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        !searchQuery
      ) {
        setIsSearchOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [searchQuery]);

  const liveGames = useMemo(
    () =>
      filterGames(
        games.filter((game) => getGameStatus(game) === GAME_STATUS.LIVE)
      ),
    [games, filterGames]
  );

  const upcomingGames = useMemo(
    () =>
      filterGames(
        games.filter((game) => getGameStatus(game) === GAME_STATUS.UPCOMING)
      ),
    [games, filterGames]
  );

  const completedGames = useMemo(
    () =>
      filterGames(
        games.filter((game) => getGameStatus(game) === GAME_STATUS.COMPLETED)
      ),
    [games, filterGames]
  );

  const allGamesCompleted =
    games.length > 0 &&
    games.every((game) => getGameStatus(game) === GAME_STATUS.COMPLETED);

  const firstSection =
    liveGames.length > 0
      ? "live"
      : upcomingGames.length > 0
        ? "upcoming"
        : "completed";

  if (showSkeleton || artificialDelay || !convexUserPicks) {
    return (
      <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-6 max-w-3xl relative z-0">
        <WeekSelector
          selectedWeek={selectedWeek}
          handleWeekChange={handleWeekChange}
        />
        <SkeletonLoader />
      </div>
    );
  }

  const renderContent = () => {
    return (
      <>
        {liveGames.length > 0 && !allGamesCompleted && (
          <div className="mb-12">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-2xl font-bold text-gray-100">Live</h2>
              {firstSection === "live" && (
                <div ref={searchRef}>
                  <SearchBar
                    isOpen={isSearchOpen}
                    setIsOpen={setIsSearchOpen}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  />
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {liveGames.map((game, index) => (
                <GameCard
                  key={index}
                  game={{ ...game, status: getDisplayStatus(game) }}
                  userPick={getUserPickForGame(game)}
                />
              ))}
            </div>
          </div>
        )}

        {upcomingGames.length > 0 && !allGamesCompleted && (
          <div className="mb-12">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-2xl font-bold text-gray-100">Upcoming</h2>
              {firstSection === "upcoming" && (
                <div ref={searchRef}>
                  <SearchBar
                    isOpen={isSearchOpen}
                    setIsOpen={setIsSearchOpen}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  />
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {upcomingGames.map((game, index) => (
                <GameCard
                  key={index}
                  game={{ ...game, status: getDisplayStatus(game) }}
                  userPick={getUserPickForGame(game)}
                  onGameStatusChange={handleGameStatusChange}
                />
              ))}
            </div>
          </div>
        )}

        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-100">Completed</h2>
          {firstSection === "completed" && (
            <div ref={searchRef}>
              <SearchBar
                isOpen={isSearchOpen}
                setIsOpen={setIsSearchOpen}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
          )}
        </div>

        {completedGames.length > 0 && (
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {completedGames.map((game, index) => (
                <GameCard
                  key={index}
                  game={{ ...game, status: getDisplayStatus(game) }}
                  userPick={getUserPickForGame(game)}
                  allGamesCompleted={allGamesCompleted}
                />
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-6 max-w-3xl relative z-0">
      <WeekSelector
        selectedWeek={selectedWeek}
        handleWeekChange={handleWeekChange}
      />
      <div className="mt-6">{renderContent()}</div>
    </div>
  );
};

export default ScoresPage;
